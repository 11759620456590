.intro-box {
	.bg;
	.padding;
	.clearfix;

	img {
	//	margin-top:@marginX2;
	}
}
.row > .intro-box {	
	//padding: 15px 20px 15px;
	padding:15px;
	@media screen and (min-width: @screen-sm) {
		//padding: 50px 20px 50px;
		padding: 30px 15px 30px 0;
	}
}
.row > .intro-box:first-child {	
	//padding: 15px 20px 15px;
	padding-top:15px;
	@media screen and (min-width: @screen-sm) {
		//padding: 50px 20px 50px;
		padding: 30px 15px;
	}
}

.intro-box-wrapper{
  margin-top: 15px!important;
}

.intro-box-headline{
  margin: 0!important;
}

.kwscilvet-groups{



  .row > .intro-box {
	//padding: 15px 20px 15px;
	padding:15px;
	@media screen and (min-width: @screen-sm) {
	  //padding: 50px 20px 50px;
	  padding: 30px 15px 30px 0;
	}

	&.intro-box-text{
	  padding-top: 30px;
	  padding-bottom: 30px;
	  font-size: 20px;
	  line-height: 28px;
	  p{
		.fontize(1.2rem,1.55rem);
	  }
	}

  }
  .row > .intro-box:first-child {
	//padding: 15px 20px 15px;
	padding-top:15px;
	@media screen and (min-width: @screen-sm) {
	  //padding: 50px 20px 50px;
	  padding: 30px 15px;
	}



  }
}


.row.equalize {
	.bg;
	
	.col-xs-12 {
		// margin-top:0
	}
}
/*
.intro-box:first-child {
		border-left:15px @white solid !important;
}
*/

.intro-box-cheat, .col-sm-4.intro-box-cheat {
	@media screen and (min-width: @bp-desk-min) {

		.bg;
		border-left:15px @white solid;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}



//fixed on service

.row.intro-box {
	margin:0;
}
.container-fluid.wonky {
	margin:0 -15px 50px -15px;
}
.container-fluid.wonky:last-of-type {
	margin-bottom:0;
}
