.breadcrumbs {
	li {
		float:left;
		
		a {	display:inline-block;
			position:relative;
			//.fontize(14px);
			.fontize(0.7rem);
			.greyMid;
			padding: 15px 10px 15px 15px;
		}
	}

	a:after, a:before {
		display:inline-block;
		content: " ";
		width: 0; 
		height: 0;
		left:0;
		position:absolute;
		margin-top:4px;
	}	
	
	a:before {
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-left: 4px solid @greyMid;
		margin-left: 2px;
	}
	a:after {
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		border-left: 4px solid @white;
	}
	
	li.active a {
		&:before {
			border-left: 4px solid @greyDark;
		}

	}

	li:first-child a:before {
		border:none;
	}
}

.no-breadcrumbs { //dummy
	height:36px;
	padding:15px 0;
}
