#main {
	#partner-carousel {
		margin-top:50px;

		h2 {
		}
	}
}

/*
 * 	Core Owl Carousel CSS File
 *	v1.3.3
 */

/* clearfix */
.owl-carousel .owl-wrapper:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
/* display none until init */
.owl-carousel{
	display: none;
	position: relative;
	width: 100%;
	-ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
	display: none;
	position: relative;
	-webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
	overflow: hidden;
	position: relative;
	width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item{
	float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
	cursor: pointer;
}
.owl-controls {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing {
	cursor:grabbing;
    cursor:grab;
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility:    hidden;
	-ms-backface-visibility:     hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}


/* MCL added */

.owl-carousel {
	.item {
		text-align:center;
		img {
			margin:0 auto;
		}
	}
}

.owl-controls.clickable {

	.owl-prev, .owl-next {
			position:absolute;
			cursor: pointer;
		top:50%;
		width:50px;
		height:50px;
		margin-top:-25px;
	}

	.owl-prev {
		left:-20px;
		@media screen and (min-width: 375px) {
			left:0;
		}

		background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22left-Ebene_1%22%20xml%3Aspace%3D%22preserve%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20enable-background%3D%22new%200%200%2040%2040%22%3E%09%3Cpath%20d%3D%22m24.927%2031.182l-11.25-9.742c-0.219-0.189-0.345-0.466-0.345-0.756s0.126-0.566%200.345-0.756l11.25-9.741c0.297-0.258%200.716-0.318%201.07-0.153%200.356%200.162%200.585%200.518%200.585%200.909v19.483c0%200.392-0.229%200.747-0.585%200.909-0.133%200.062-0.274%200.091-0.415%200.091-0.236%200-0.47-0.084-0.655-0.244z%22%20fill%3D%22%23A2A1A1%22%2F%3E%3C%2Fsvg%3E');
		background-position:center left;
	}

	.owl-next {
		left:auto;
		right:-15px;
		@media screen and (min-width: 375px) {
			right:0;
		}
		background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22right-Ebene_1%22%20xml%3Aspace%3D%22preserve%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20enable-background%3D%22new%200%200%2040%2040%22%3E%09%3Cpath%20d%3D%22m14.165%2031.426c-0.141%200-0.283-0.029-0.416-0.091-0.356-0.162-0.584-0.518-0.584-0.909v-19.484c0-0.392%200.229-0.747%200.584-0.909%200.354-0.165%200.773-0.104%201.07%200.153l11.251%209.741c0.219%200.189%200.345%200.466%200.345%200.756s-0.126%200.566-0.345%200.756l-11.251%209.742c-0.185%200.161-0.419%200.245-0.654%200.245z%22%20fill%3D%22%23A2A1A1%22%2F%3E%3C%2Fsvg%3E');
		background-position:center right;
		}

	.owl-pagination {
		display:none;
	}
}

.owl-prev, .owl-next {
	&.disabled {
		opacity:0.5;
		cursor: default;
	}
}

.owl-carousel-seminar, .owl-carousel-content {
	
	.owl-controls.clickable {
		.owl-prev {
			left:-28px;
			@media screen and (min-width: 768px) {
				left:-35px;
			}
		}
		.owl-next {
			right:-28px;
			@media screen and (min-width: 768px) {
				right:-35px;
			}
		}
		.owl-pagination {
			display:block;
			text-align:center;
			
			// safeproof in case too many:
				max-width: 300px;
				margin: 0 auto;
				overflow: hidden;
				max-height: 50px;
			

			.owl-page {
				display: inline-block;
				span {
					display: inline-block;
					padding: 20px 5px;
				}
				
				span:after {
					content: "";
					display:block;
					width:11px;
					height:11px;
					.bg(@grey);
					border-radius:100%;
				}
				&.active {
					span:after {
						.bg(@green);
					}
				}
			}
		}



	}
}



//heretige browser:
.no-svg {
	.owl-prev {
		background-image:url('../img/icons.png');background-position:0 -440px;background-repeat:no-repeat;
	}
	.owl-next {
		background-image:url('../img/icons.png');background-position:0 -660px;background-repeat:no-repeat;
	}
}