
.keywords {
	b, strong {
		&:before {
			display:inline;
			content: '"';
		}
		&:after {
			display:inline;
			content: '"';
		}
	}
}

[lang=de] .keywords {
	b, strong {
		&:before {
			content: "«";
		}
		&:after {
			content: "»";
		}
	}
}

.result.item {
	h2, .item-title {
		.fontizeBody;
		font-weight:bold;
		border:none;
		margin:0;
		padding:0;
	}
	p {
		.margin-top-only;
		padding:0;
	}
}