// main: base.less

body > footer {
	
	ul {padding-left:15px;}
	ul ul {padding-left:0;}
	
	* {.fontize16 !important;}
	//.fontize16;
	padding-top:35px;
	.padding-bottom;
	.gradient;
	//min-height: 620px;
	// .fs16;
	.white;
	
	h1 {margin:0; padding:0;}
	
	hr {
		border:0;
		
		&:after {
			content:"";
			display:block;
			height:3px;
			background:@blueLight;
			border-top:  2px @blueLight solid;
			border-bottom:  2px @blueLight solid;
			border-radius:4px;
		}
	}
	
	nav li, nav a {} //.fs16;}
	
	nav ul li ul {.margin-top;} 
	
	a, a:visited,  a:hover {
		.white;
		cursor: pointer;
	}
	a:hover {
		color:@green;
		text-decoration: none;
	}
	
	.copyright {
		.blueLight;
	}
	#agbs {
		float:right;
		ul {
			li {
				float:left;
				
				a {.padding-lr(7px);}
			}	
		}
	}
	
	.snazzy {
		.margin-bottom;
	}
	
	
	.expando {
		.margin-bottom;
		
		.header {
			.margin-bottom;
		}
		button {
			position:absolute;
			margin-top:7px;
			padding:0;
		}
		.summary, .details {
			 padding-left: 30px;
		}
	}
	
	
	
	//hotlines
	address {
		.margin-top;
		ul {
			.columns-2;
			padding-left:0;
		}
	}
	
	//social
	aside {
		.clearfix;
		header {
			.margin-bottom;
		}
		.margin-bottom;
	}
	


}

//footer resize fix
/*
@media screen and (min-width: 976px) and (max-width: 1159px) {

	footer .container-fluid:first-of-type .col-sm-4 {
		//width:25%;
	}
	footer .container-fluid:first-of-type .col-sm-2 {
		//width:25%;
	}

	footer {

		li.col-md-2 {
			padding-left:5px;
			padding-right:5px;
			font-size:14px;
		}

		a.snazzy {
			.fontize(14px);
		}

	}
}
*/

	a.snazzy {
		min-width:155px !important;
	}
@media screen and (min-width: @bp-lg-min) and (max-width: 1265px) {
	.col-md-2 {
		padding-left:0;
	}
	/*
	a.snazzy {
		//.fontize(14px) !important;
		// .fontize(0.7rem) !important;
		padding-top:6px;
		padding-right:2px;
		min-width:160px !important;
	}
*/
}

/*
@media screen and (min-width: 976px) and (max-width: 1202px) {
		a.snazzy {
			.fontize(14px) !important;
			padding-top:5px;
	}
}
*/
