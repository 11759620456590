/* GENERIC: here */

// some mixins also in variables, or near relevent css if it makes more sense than to be here.


// https://github.com/LeaVerou/rgba.php
.rgba(@Rgba, @rGba, @rgBa, @rgbA) {
	background: e("url('../dev/includes/libs/rgba.php/rgba(") @Rgba, @rGba, @rgBa, @rgbA e(") ')");
	//usage:	.rgba(255, 0, 0, 0.3);	     outputs: { background: url('../dev/includes/libs/rgba.php/rgba(255, 0, 0, 0.3)'); }
}

.me-4{
margin-right: 50px;}


// ANIMATE: keyframes  fadein  &  fadeout
.fadeStyle(@start, @end) {
	from {opacity:@start;}
    to {opacity:@end;}
}

/* 'from' & 'to' selectors generated by less mixin for animation keyframes: (ignore) */
.fadeStyleIn {
	.fadeStyle(0,1);
};
.fadeStyleOut {
	.fadeStyle(1,0);
}

/* FADE IN: */
@keyframes fadein { .fadeStyleIn; }
@-moz-keyframes fadein { .fadeStyleIn; } // Firefox
@-webkit-keyframes fadein { .fadeStyleIn; } // Safari and Chrome
@-o-keyframes fadein { .fadeStyleIn; } // Opera

// FADE OUT: */
@keyframes fadeout { .fadeStyleOut; }
@-moz-keyframes fadeout { .fadeStyleOut; } // Firefox
@-webkit-keyframes fadeout { .fadeStyleOut; } // Safari and Chrome
@-o-keyframes fadeout { .fadeStyleOut; }// Opera


/* TEXT STYLES */
.bold {
	font-weight:bold;
}

.uppers() {	text-transform: uppercase; }
.lowers() {	text-transform: lowercase; }
.cap() {	text-transform: capitalize; }

.underline() {text-decoration: underline;}
.noUnderline() {text-decoration: none;}

.align-center {
	text-align:center;
}
img.align-center, .align-center-img, .align-center-img img {
	margin: 0  auto;
}
.align-right {
	text-align:right;
}
.align-left {
	text-align:left;
}

.vertical-center() {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}


