// main: base.less

ol.reading-list {
	padding:0;
	
	>li {
		list-style-position: inside;
	}
	
	>li + li {
		margin-top:30px;
	}
	
}