// main: base.less

table.container-fluid {
	width:100%;
	
	td, th {
		float:left;
	}
	td div {display:block; clear:both; } //wtf, but needed ?!
	
	tbody tr:after, tbody tr:before {
		content: "";
		height:15px;
		display:block;
		clear:both;
	}
	
}


#main {

	.table > .row, tbody tr {
		.border-bottom;
	}
	
	
	th, .th {
		.bg(@greyLight);
		vertical-align:middle;
		min-height:30px;
		line-height:30px;
		padding:0;

		&:first-child {
			padding:0 0 0 20px;
		}
	}
	.th {
		padding-left:15px;
		margin-left:-15px; // post font-change change
	}

	tr:last-child td {
		border:none;
	}
	td, .td {
		vertical-align:top;
		padding:0;
		@media screen and (min-width: @screen-md) {
		//	padding:30px 0;	
		}

		&:first-child {
			font-weight:bold;
		}
		
		p {
			padding:0;
			@media screen and (min-width: @screen-md) {
				padding:0 0 0 15px;
			}
			margin:0;
		}
		.sub {
			font-weight:normal;
		}


		span {
			float:left;
			display:inline-block;
		}

		span.img {
			width:155px;
			.margin-bottom;

			span.icon-loop {
				background-position: -10px -12px;
				float:left;
				width:30px;
				height:40px;
			}

			img {float:left;}
		}

		span.icon-tick {
			width:40px;
			height:40px;
			position:absolute;
			background-position:0 -5px;
		}

		.aft-icon {padding-left:40px;}


	}
}

.fullWidth {
	width:100%;
}


#main table.hover {
	.bg(@white);
	tr:hover {
		.bg(@greyLighter);
	}
	tr td:first-child {
		.padding-left;
	}
}


.hoverTd {
	tr:hover {
		td {
			.bg(@greyLighter);
		}
	}
}


table.a-z, .table.a-z {
	margin-top: 15px!important;

  	th{
	  .fontize18;
	}

	td{
		a{
		  color: #000000;
		  text-decoration: none;
		  &:hover{
			color: inherit;
			text-decoration: none;
		  }

          &.more{
            color: @green!important;
          }

		}
	}

	td.col-sm-1, td[class="col-sm-1"] { //hide the Letter cell for mobile
		display:none !important;
		@media screen and (min-width: @screen-sm) {
			display:table-cell !important;
		}
	}

  	tr:last-child{
	  border-bottom: none!important;
	}

	td:first-child, .td:first-child {
	  	text-transform: uppercase;
	  	font-weight: normal!important;
		.greyMid;
		.fontize36;
	}

  .subline, .productgroups{
	.fontize18;
  }

}

table.seminar,table.features{

  margin-top: 0!important;

  tr{
    td{
      border-top: none!important;
      padding: 0 10px 0 0!important;

      &:first-child{
        padding-left: 20px!important;
        @media screen and (max-width:@screen-xs-max ){
          padding-left: 0px!important;
        }
      }

      a{
        color: inherit!important;
        text-decoration: none;
        &:hover{
          text-decoration: none;
        }

        &.more{
          color: @green!important;
        }
      }

      &.marg-top-md{
        @media screen and (min-width: @screen-sm-min) and (max-width:@screen-md-max) {
          padding-left: 20px!important;
        }
      }
    }
  }

  p{
    padding: 0!important;
  }
}

html.touch table.a-z td a, html.touch table.seminar td a { color: @green; }
