// main: base.less

.sidebar {
	width:50px;
//	position:relative;
	//float:right;
	//height:0;
	
	ul {
		/*
		opacity:0.5;
		&:hover {opacity:1;}
		*/
		
	//	margin-top:137px; //150px;
	//	position:fixed;
	//	z-index:2000;
		width:50px;
		left:auto;
		right:0;
		.gradient;
		background-size:auto 100%;
		
		li {
			span {
				display:none;
				.bg(@white);
				margin: -50px 0 0 -252px;
				width: 250px;
				position: absolute;
				.border(@green);
				min-height:50px;
				line-height:50px;
				height:50px;
				verlical-align:50px;
				text-align:center;
				padding:0 5px;
				
				white-space: nowrap;
				overflow:hidden;
				text-overflow: ellipsis;
				
				
				// line-height:27px;
				// &:first-line {.blue; line-height:50px;}
			}
			&:hover span {display:inline-block;}
			
			a {
				background-position:center center;
				display:block;
				height:50px;
				width:50px;
				text-indent:-9999px;
				color:transparent;
			}
			a:hover {
				background-color:@green;
				//background-size:auto 45px;
				/*
					-webkit-transform: rotate(15deg);
					-moz-transform: rotate(15deg);
					-ms-transform: rotate(15deg);
					-o-transform: rotate(15deg);
					transform: rotate(15deg);
				*/
				
			}
			/*
			a:hover:nth-child(3) {
				background-size:auto 80%;
			}
			*/
			border-bottom:3px solid @blueLight;
		}
		li:last-child {border-bottom:none;}
	}
	
}

#afx {position: absolute; 
	right: 0; 
	top: 150px; 
z-index:3000;}
#afx.affix-top {  } 
#afx.affix { position: fixed; top: 20px; }



//tweaks for positioning heritage browsers
.no-svg {
	
	.sidebar {
		
		li a:hover {
			background-color:transparent;
		}
	
		a.icon-info {
			background-position: 5px -395px;
		}
		a.icon-phone {
			background-position: 9px -590px;
			height: 35px;
			margin: 5px 0;
		}
		a.icon-mail {
			background-position: 9px -517px;
			height: 35px;
			margin: 5px 0;
		}
		a.icon-calendar {
			background-position: 4px -35px;
		}
		a.icon-f {
			background-position: 5px -202px;
			height: 35px;
			margin: 5px 0;
		}
		a.icon-in {
			background-position: 5px -362px;
			height: 35px;
			margin: 5px 0;
		}
		a.icon-ut {
			background-position: 5px -816px;
		}
	}
}

// fix if not enough height:
@media screen and (max-height: 500px) {
	#afx.affix.sidebar {
		position:absolute !important;
	}
}
