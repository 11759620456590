/* *** COLORS: *** */
@green: #4fa293; .green() {color:@green;}
@blue: #074590; .blue() {color:@blue;}
@white: #fff; .white() {color:@white;}
@blueLight: #759ab9; .blueLight() {color:@blueLight;}

@grey: #666b6e; .grey() {color:@grey;}
@greyDark: #494949; .greyDark() {color:@greyDark;}
@greyMid: #969696; .greyMid() {color:@greyMid;}
@greyLight: #d7d7d7; .greyLight() {color:@greyLight;}
@greyLighter: #ececee; .greyLighter() {color:@greyLighter;}

@red: #ff0000; .red() {color:@red;}


// .standardize();

// *** breakpoints *** //
@bp-mob-max:767px;
@bp-desk-min: 768px;
// (from design):
@bp-nav:  907px; //888px; // 864px;
@bp-nav-form: 907px; // 864px;
@bp-nav-wide: 1000px;

@bp-lg-min: 991px; 
@bp-md-max: 990px; 

@bp-max-screen: 1280px; 

@bp-indentify: 1328px;




//BOOTSTRAP BREAKPOINTS (un-edited)
// Extra small screen / phone
//** Deprecated `@screen-xs` as of v3.0.1
@screen-xs:                  480px;
//** Deprecated `@screen-xs-min` as of v3.2.0
@screen-xs-min:              @screen-xs;
//** Deprecated `@screen-phone` as of v3.0.1
@screen-phone:               @screen-xs-min;

// Small screen / tablet
//** Deprecated `@screen-sm` as of v3.0.1
@screen-sm:                  768px;
@screen-sm-min:              @screen-sm;
//** Deprecated `@screen-tablet` as of v3.0.1
@screen-tablet:              @screen-sm-min;

// Medium screen / desktop
//** Deprecated `@screen-md` as of v3.0.1
@screen-md:                  992px;
@screen-md-min:              @screen-md;
//** Deprecated `@screen-desktop` as of v3.0.1
@screen-desktop:             @screen-md-min;

// Large screen / wide desktop
//** Deprecated `@screen-lg` as of v3.0.1
@screen-lg:                  1200px;
@screen-lg-min:              @screen-lg;
//** Deprecated `@screen-lg-desktop` as of v3.0.1
@screen-lg-desktop:          @screen-lg-min;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:              (@screen-sm-min - 1);
@screen-sm-max:              (@screen-md-min - 1);
@screen-md-max:              (@screen-lg-min - 1);


@grid-float-breakpoint:@screen-sm;

//indent:
@indent: 55px; //45px;

// background:
.bg(@color:@greyLighter) {	background-color:@color;	}
.bgMid {	background-color:@greyMid;	}
.bgDark {	background-color:@greyDark;	}


// borders:
.border(@color: @greyLight) {
	border:solid 1px @color;
}
.border-top(@color:@greyLight ) {
	border-top:solid 1px @color;
}
.border-bottom(@color:@greyLight ) {
	border-bottom:solid 1px @color;
}


// margins
@margin: 15px; 
@marginX2: (@margin *2);
.margin-top0 {margin-top:0};
.margin-top(@mt:@margin) {	margin-top:@mt;	}
.margin-top-only(@mt:@margin) {	margin:@mt 0 0 0;	}

.margin-bottom(@mb:@margin) {	margin-bottom:@mb;	}
.margin-bottom-only(@mb:@margin) {	margin:0 0 @mb 0;	}

.margin-tb(@mb:@margin) {	margin-top:@mb; margin-bottom:@mb;	}
.margin-tb-only(@mb:@margin) {	margin:@mb 0 @mb 0;	}

.margin-left {	margin-left:@margin; }
.margin-right {	margin-right:@margin; }

.margin-lr(@mlr:@margin) {	margin-left: @mlr; margin-right: @mlr;	}
.margin-lr-only(@mlr:@margin) {	margin:0 @mlr;	}

.margin-topX2 {	margin-top:@marginX2;	}
.margin-bottomX2 {	margin-bottom:@marginX2;	}



// padding:
@padding:20px;

.padding(@padd:@padding) {	padding:@padd;	}
.padding-top0 {padding-top:0};
.padding-top(@padd:@padding) {	padding-top:@padd;	}
.padding-top-only(@padd:@padding) {	padding:@padd 0 0 0;	}

.padding-bottom(@padd:@padding) {	padding-bottom:@padd;	}
.padding-bottom-only(@padd:@padding) {	padding:0 0 @padd 0;	}

.padding-tb(@padd:@margin) {	padding-top:@padd; padding-bottom:@padd;	}
.padding-tb-only(@padd:@margin) {	padding:@padd 0 @padd 0;	}

.padding-left(@padd:@padding) {	padding-left:@padd;	}

.padding-lr(@padd:@padding) {	padding-left: @padd;	padding-right: @padd;	}
.padding-lr-only(@padd:@padding) {	padding:0 @padd;	}


// standard box
.box {
	.bg;
	.padding;
}

.boxGreen {
	.bg(@green);
	.white;
}


// gradient:
.gradient(){
	background: #35788b; /* Old browsers */
	/* IE9 SVG, needs conditional override of 'filter' to 'none' */
	background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzM1Nzg4YiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMTM1ODgiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35788b', endColorstr='#013588',GradientType=0 ); /* IE6-8 */
	background: -moz-linear-gradient(top,  #35788b 0%, #013588 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#35788b), color-stop(100%,#013588)); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top,  #35788b 0%,#013588 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top,  #35788b 0%,#013588 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top,  #35788b 0%,#013588 100%); /* IE10+ */
	background: linear-gradient(to bottom,  #35788b 0%,#013588 100%); /* W3C */
}
.grad-green, .gradient-green {
	background: #69d5c7;
	background: -moz-linear-gradient(top,  #69d5c7 0%, #4fa391 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#69d5c7), color-stop(100%,#4fa391));
	background: -webkit-linear-gradient(top,  #69d5c7 0%,#4fa391 100%);
	background: -o-linear-gradient(top,  #69d5c7 0%,#4fa391 100%);
	background: -ms-linear-gradient(top,  #69d5c7 0%,#4fa391 100%);
	background: linear-gradient(to bottom,  #69d5c7 0%,#4fa391 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#69d5c7', endColorstr='#4fa391',GradientType=0 );
	/* 
	//Left to right
	background: rgba(79,163,145,1);
	background: -moz-linear-gradient(left, rgba(79,163,145,1) 0%, rgba(105,213,199,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(79,163,145,1)), color-stop(100%, rgba(105,213,199,1)));
	background: -webkit-linear-gradient(left, rgba(79,163,145,1) 0%, rgba(105,213,199,1) 100%);
	background: -o-linear-gradient(left, rgba(79,163,145,1) 0%, rgba(105,213,199,1) 100%);
	background: -ms-linear-gradient(left, rgba(79,163,145,1) 0%, rgba(105,213,199,1) 100%);
	background: linear-gradient(to right, rgba(79,163,145,1) 0%, rgba(105,213,199,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4fa391', endColorstr='#69d5c7', GradientType=1 );
	*/
}







	
/*
:60px;
:50px;
:28px;
:18px;
*/
