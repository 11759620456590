// main: base.less


// z-index fix:
//LANG
ul.nav.navbar-right {
    z-index:2003;
}


//styles all:
#search-magic {
    margin-bottom: 30px;

    .results, form {
        background:#fff;

        .input-group> {
            input.form-control {
                height:36px;
                padding: 0 5px;
            }
            button {
                height:36px;
                min-width:36px;
            }
        }
    }

    .results {
        .border;
        border-top:0;
        padding: 30px 30px 0 30px;
		// margin-top: 35px;
		margin-right: 15px;
		display: none;

        a.button {
            color:#fff !important;
            &:hover {
                color:@blue !important;
            }
        }

        .col-xs-12 {
            margin-bottom: 30px;
        }
    }



    article.result {
        margin-top: 30px;
        color:#969696;

        +article.result {
            .border-top;
            padding-top:15px;
            margin-top: 15px;
        }
    }

    // black text for artikles:
    .artikle article.result {
        color:#000;
    }

}

// hide results if no search has been done:
@media screen and (max-width: ( @grid-float-breakpoint - 1) ) {
    #search-magic:not(.active) {
        .results {
            display:none;
        }
    }
}


// desktop: (HOVER)
// desktop:
@media screen and (min-width: @grid-float-breakpoint) {

    #search-magic {
        // overflow: hidden;

        width: 220px;
        height: 36px;
		/*
        position: absolute;
        z-index: 2002;
        right: 0;
        top: 66px;
		*/
		position: relative;
		align-self: flex-end;
		// top: 66px;

        button[type="submit"].btn {
            transition:background linear 0.1s;
        }

        input {
            transition: width linear 0.3s ;
            width:155px;
            float:right;
        }


		form {
			position: relative;
			min-width:155px;
		}
		
		// *** WITH HOVER...!
        &.focus,
        &:hover,
        &:focus,
        &:active {
			/*
            width: 100%;
            height: initial;
            height: auto;
            // padding-left: 170px;
			position: absolute;
			right: 0;

            //z-index:2004; // so its above the lang.
            z-index: 2; // so its NOT above the lang.
			*/
			.input-group {
				float: right;
				/*
				position: absolute;
				/*
				width: 1060px;
				max-width: ~"calc(100vw - 180px)";
				*/
				width: ~"calc(100vw - 180px)";
				max-width: 1085px;
				
				// right: 0;
				position: relative;
				z-index: 9999;
				overflow: visible;
			}


            form {
                width:100%; // ff fix.
				min-width:122px;
				
                input {
                width:100%;}
                button[type="submit"].btn {
                    z-index: 2;
                    // NOTE: .grad-green; not allowed here (because of background IMG: arrow: cant use gradient - so we fake with shaddow)
                    background-color:#69d5c7 !important;
                    box-shadow:inset #4fa391 0 -6px 18px;

                    &.icon-arrow {
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBpZD0iYXJyb3ctRWJlbmVfMSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDQwIDQwIiB3aWR0aD0iNDAiIHZlcnNpb249IjEuMSIgeT0iMHB4IiB4PSIwcHgiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQwIDQwIj4NCiAgPHBhdGggZD0ibTE3LjU5NCAyNy43MjljLTAuMzA3IDAtMC42MTQtMC4xMTItMC44NTYtMC4zMzktMC41MDMtMC40NzMtMC41MjgtMS4yNjQtMC4wNTUtMS43NjdsNC45MDUtNS4yMjItNC44NTktNC42NjRjLTAuNDk4LTAuNDc4LTAuNTE0LTEuMjY5LTAuMDM2LTEuNzY3IDAuNDc5LTAuNSAxLjI2OS0wLjUxNiAxLjc2OC0wLjAzNmw2LjY0NCA2LjM3NS02LjU5OCA3LjAyNGMtMC4yNDggMC4yNjQtMC41OCAwLjM5Ni0wLjkxMyAwLjM5NnoiIGZpbGw9IiNmZmZmZmYiLz4NCjwvc3ZnPg0K) !important;
                    }

                }
            }
        }
        .results {
            height:0;
            overflow: hidden;
            opacity:0;
            transition: max-width linear 0.3s;

            box-shadow:rgba(0,0,0,0.5) 0px 2px 10px;
            margin-bottom:15px;

			position: absolute;
			
			width: ~"calc(100vw - 180px)";
			max-width: 1085px;

			right: 0;
			z-index: 9998;
			overflow: visible;

//			margin-top: 35px;
//			margin-right: 15px;
        }


        &.focus,
        &:hover,
        &:active,
        &:focus {
            &.active {
                .results {
                    height:initial; height:auto;
                    display:block;
                    opacity:1;
                }
            }
        }

    }

}


// 2020 fixes (due to more menu items obstructing search) :
.nav.navbar-nav {
     z-index: 3001;
     // background: #fff;
}
#search-magic {
    &.focus,
    &:hover,
    &:focus,
    &:active {
        z-index:3002;
    }
}

// make sure OPEN menus have space
.dropdown.open .dropdown-menu {
    z-index: 99999;
}
// lang at top
ul.nav.navbar-right {
    z-index: 9999;
}

@media screen and (min-width: @grid-float-breakpoint) {
	div.navbar-collapse.collapse {
		// display: block !important;
		display: flex !important;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-content: stretch;
		align-items: center;
		margin: 0;
		padding: 66px 0 0 0;
	}
	// lang
	.nav.navbar-right {
		position: absolute;
		right: 60px;
		top: 0;
	}
	// menu:
	.navbar ul.navbar-nav {
		margin: 0;
		flex: 1 1 auto;
		align-self: auto;
		>li >a {
			line-height: .5rem;
		}
	}
	// search:
	#search-magic {
		top: 6px;
		margin: 0;
	    flex: 0 1 auto;
    	align-self: baseline;
		outline: 0; // chrome hover fix
		
		.container-fluid {
			padding-right: 0;
		}
	}
}