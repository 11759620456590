.seminar {
	.meta {
		.margin-bottom;
		.margin-top;
		font-weight:bold;
	}
	
	.title {
		font-weight:bold;
	}
	
	
	time {
		display:inline;
		.greyDark;
		
		span {display:block;}
	}
	td time {
		margin-bottom:10px;
		display:block;
		clear:both; //wtf
	}

	.warning {
		font-weight:bold;
		&:before {
			content: " / ";
			.greyDark;
		}
		.red;
	}
	td .warning:before {
		display:none;
	}

	.tag {
		.greyMid;
		display:block;
	}
	
	.bold {
		margin-bottom:5px;
	}
	
	.more {
		display:inline-block;
		.margin-top;
	}	
}


// for general reuse:
.warning {
	font-weight:bold;
	.red;
}


//stupid styles on details page (bare NO resemblence to styleguide whatsoever)
.indentify > .container-fluid > h1 + h2 {
	border-bottom:none !important;
	font-weight:bold;
}

h2 + .warning {}

.kwscilvet-events{
  .row.equalize{
	background: none;
  }
}

.event-details{
  .rte-text{
	p:last-child{
	  margin-bottom: 0px!important;
	}
  }
}


.responsiv-map{
  position: relative;
  padding-bottom: 90%;
  height: 0;
  overflow: hidden;

	@media screen and (min-width: @screen-sm) {
		padding-bottom: 44%;
	}

  & iframe,
  & object,
  & embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  }
}
