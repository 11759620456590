// main: base.less

body {
	padding:0;
	background: @white;
	min-width:320px;
}

#main {
	@media screen and (min-width: @screen-sm) {
		min-height:500px;
	}
}

@bp-indent: 1400px;
.indentify {
	margin:0 15px;
	@media screen and (min-width: @bp-desk-min) {
		margin:0 @indent;

		/* no design is meant that way...
		.container-fluid:first-of-type {
			padding:0;
		}
		*/
	}
	@media screen and (min-width: @bp-indent) {
		margin:0 0 0 15px;
	}
}

.marginTop0, #main .container-fluid.marginTop0 {margin-top:0;}



.container-fluid {
	max-width: 1280px;
}

.columns-2 {
	@media screen and (min-width: @bp-desk-min) {
		-webkit-column-count: 2; /* Chrome, Safari, Opera */
		-moz-column-count: 2; /* Firefox */
		column-count: 2;
	}
}

.container-fluid.layoutOnly {
	> .row > [class^="col-"] {
		padding-left:0;
		padding-right:0;
	}
}

/*
.row {
    display: table;
}
*/
.equal-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    flex:1 0 auto;
}

.align-right {
	text-align:right;
}
.align-center {
	text-align:right;
}

//ONLY FLOATS ON NON MOB.
@media (min-width: @screen-sm) {
	.float-right {
		float:right;
	}
	.float-left {
		float:left;
	}
}

//responsive Fixes:
@media screen and (max-width: @screen-xs) { // ?!? check all. 
// @media screen and (max-width: @screen-xs) {
	.row > div.col-xs-12 {
		.margin-top;
	}
	.row > div.col-xs-12:first-child {
		margin-top:0;
	}
}

// home 4 col
@media screen and (max-width: @screen-md) {
	.col-md-pull-9, {
		.margin-top;
	}
}
@media screen and (max-width: @screen-sm) {
	.col-md-push-3 {
		.margin-top;
	}
	.col-md-push-3:first-child {
		margin-top:0;
	}
}

.margin-bottom {
	.margin-bottom;
}
.margin-bottom-30 {
	margin-bottom:30px;
}
.margin-bottom-40 {
	margin-bottom:40px;
}


.break-15 {
	height:15px;
	.clearfix;
}
.break-30, .break-40, .break-50, .break-80, .break-100 {
	height:30px;
	.clearfix;
}
@media (min-width: @screen-sm) {
	.break-30 {
		height:30px;
		.clearfix;
	}
	.break-40 {
		height:40px;
		.clearfix;
	}
	.break-50 {
		height:50px;
		.clearfix;
	}
	.break-80 {
		height:80px;
		.clearfix;
	}
	.break-100 {
		height:100px;
		.clearfix;
	}
}
	
.no-padding {
	padding:0;
}
		

//use 'marg-top-md' to get margin-top when block element
.margin-top-lg, .marg-top-lg {.margin-top;}
.margin-bottom-lg, .marg-bottom-lg {.margin-bottom;}

@media (max-width: @screen-md-max) {
	.margin-top-md, .marg-top-md {.margin-top;}
	.margin-bottom-md, .marg-bottom-md {.margin-bottom;}
}
@media (max-width: @screen-sm-max) {
	.margin-top-sm, .marg-top-sm { .margin-top; }
	.margin-bottom-sm, .marg-bottom-sm { .margin-bottom; }
}
@media (max-width: @screen-xs-max) {
	.margin-top-none-xs, .marg-top-none-xs, div.margin-top-none-xs.col-xs-12 {margin-top:0 !important; }
	.margin-top-xs, .marg-top-xs {.margin-top; }
	.margin-bottom-xs, .marg-bottom-xs {.margin-bottom;}
}

// kill too much space on 'Unsere Produkte' and similar
h3.border-bottom, h2.border-bottom {
	+ .container-fluid {
		margin-top:0 !important;
	}
}


#seminar-carousel {
	margin-top:60px;
	margin-bottom:0;
}
