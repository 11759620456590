// main: base.less

a, a:visited {
	.green;
	.noUnderline;
	cursor: pointer !important;
	outline:none;
	outline:0;
	
	&:hover {
		.green;
		.underline;
	}

}

.title-link{
  text-decoration: none!important;
}

.link-element{
  &:hover{
    a.more{
      .underline;
    }
  }
}

a:focus {
	outline:none;
	outline:0;
}

.active a {
	.green !important;
}

a.snazzy {
	.greyDark !important; //ie thing
	
	display:inline-block;
	.bg;
	.border;
	border-radius: 0 10px 0 0;
	background-position:-10px center;
	text-indent: 20px;
	height:30px;
	line-height:30px !important;
    vertical-align: middle;
	padding-right:7px;
    // width: 100%;
	
	&:hover {
		.bg(@white);
		.blue !important;
		.noUnderline;
	}
	
}

a.more {
	//text-transform: lowercase;
	// display:inline-block;

  	&:hover{
	  &:after {
		text-decoration: none!important;
	  }
	}

	&:after {
//		position:absolute;
		display:inline-block;
		margin:1px 0 0 2px;
		content: " >";
	    text-decoration: none!important;
	}
}


h1, h2, h3 {
	a.icon-arrow {
		.greyDark;
		//background-position:center right;
		background-position:right 14%;
		padding-right:35px;
		//background-position: right 2px;
		
		&:hover {
			.green;
			text-decoration: none;
		}
	}
}
h3.h4 {
	a.icon-arrow {
		background-position:center right;
	}
}
// also .no-svg
.no-svg, .no-js {
	h1, h2, h3 {
		a.icon-arrow {
			background-position:right 0;
		}
	}
	h3.h4 {
		a.icon-arrow {
			background-position:right 0;
		}
	}
}

a.icon-gps {
	.margin-top;
	height: 40px;
	display: inline-block;
	vertical-align: middle;
	line-height: 40px;
	background-position: -10px 0;
	padding-left: 25px;
}

.goUp {
	text-align:right;
	.border-top;
	margin-top:100px;
	a {
		.greyDark;
		display:inline-block;
		background-position:right center;
		padding:15px 35px;
	}
}


ul.tab-buttons {
	.clearfix;
	margin:@margin 0;
	//.margin-top-bottom;
	
	li {
		float:left;
		margin:0 15px 0 0;
		
		a { text-decoration: none;
			.bg;
			.greyDark;
			display:inline-block;
			padding:5px 10px;
			
			&:hover {
				text-decoration:none;
				.bg(@blue);
				.white;
			}
		}
	}
	li.active a {
		.bg(@green);
		.white !important;
	}
}

ul.list {
	.margin-bottom;
	.clearfix;
}

ul.bullet, ul.bullets, .rte-text ul:not(.tick) {
	list-style: disc inside;
	padding-left:0;
	margin-bottom:60px; // why 60px? because it says so in design...?!?
	.clearfix;
	
	li {
		//list-style: disc inside;
		//list-style: disc outside;
    	list-style: outside none disc;
		list-style-type: disc;
		list-style-position: outside;
		list-style-image: none;
		margin-left:20px;
	}
}

a.icon-up-arrow {
	&:hover {
		text-decoration: none;
		.green;
	}
}

//inline P button link:
p .internal-link.button {
	&:hover {
		color:@blue !important;
		text-decoration:none;
	}
	
	&.buttonHeight {
		height: 55px;
		line-height: 55px;
		vertical-align: middle;	
	}

}