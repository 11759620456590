.social {
	
	ul {
		min-width:160px; //force block display;
		margin:0;
		padding:0;
	}
	
	li a {
		display:block;
		float:left;
		width:40px;
		height:40px;
		text-indent:-9999px;
		color:transparent;
	}
}