/* mixins for reset */

.link () {
	.green;
	.noUnderline;
	cursor: pointer !important;
	outline:none;
	outline:0;

	&:hover {
		.green;
		.underline;
	}
}

.inherit () {
	font-family: inherit;
	font-size:inherit;
	color:inherit;
	width:inherit;
	height:inherit;
	margin:inherit;
	padding:inherit;
	background-color: inherit;
}
.standardBG () {
	 .bg;
}


/* CSS Document */

/****************************************************************************************
# Default css file for content edited with htmlArea RTE
#
# Defines a few class selectors that can be simply taken as examples by anyone who needs to configure the RTE.
# This css file is referenced by property RTE.default.contentCSS in the default configuration of htmlArea RTE.
# ***************************************************************************************/

/* Left, center, right and justify alignment of text in block elements*/
p.align-left, td.align-left, th.align-left, h1.align-left, h2.align-left, h3.align-left, h4.align-left, h5.align-left, h6.align-left, div.align-left, address.align-left { text-align: left; }
p.align-center, td.align-center, th.align-center, h1.align-center, h2.align-center, h3.align-center, h4.align-center, h5.align-center, h6.align-center, div.align-center, address.align-center { text-align: center; }
p.align-right, td.align-right, th.align-right, h1.align-right, h2.align-right, h3.align-right, h4.align-right, h5.align-right, h6.align-right, div.align-right, address.align-right { text-align: right; }
p.align-justify, td.align-justify, th.align-justify, h1.align-justify, h2.align-justify, h3.align-justify, h4.align-justify, h5.align-justify, h6.align-justify, div.align-justify, address.align-justify { text-align: justify; }

/* Two frame examples taken from the example CSS file of CSS Styled Content extension and applied to p and table block elements.*/
p.csc-frame-frame1, table.csc-frame-frame1 { .inherit; }
p.csc-frame-frame2, table.csc-frame-frame2 { .inherit; }

/* Bullet styles for unordered lists.*/
ul.component-items { .inherit; list-style-type: circle; }
ul.action-items { .inherit; /* list-style-image: url(img/red_arrow_bullet.gif); */ }

/* Numbering styles for ordered lists.*/
ol.component-items-ordered { .inherit; list-style-type: lower-roman; }
ol.action-items-ordered { .inherit; list-style-type: lower-greek; }

/* Three inline text colors taken from the color scheme of CSS Styled Content extension.*/
span.important { /* color: #8A0020; */ }
span.name-of-person { /* color: #10007B; */ }
span.detail { /* color: #186900;*/  }



/* Selectors for the default configuration of the link accessibity feature.*/

;
a.external-link { .link; }
a.external-link-new-window { .link; }
a.internal-link { .link; }
a.internal-link-new-window { .link; }
a.download { 
    .button;
    display:block;
}
//a.mail { .link; }

/* Default selector for indentation.*/
div.indent { margin-left: 2em; }


/* OVERRIDE FUNKY STYLES FROM RICH TEXT EDITOR */
.rte {
    h1, h2, h3, h4, h5, h6, h7 { .inherit; } // inherit parent set style
    big {
		.inherit;
    }
    small {
		.inherit; 
    }
    
    p { .inherit; }
    table {
        tr {}
        td, td p { .fontizeBody; }
        th, th p { .fontizeBody; font-weight:bold;}
    }
    
    ul, ol { //from bootstrap
        .inherit;
    }
    li {.inherit;}
    
}
/* 
?????

#CybotCookiebotDialogBody {
	max-width: 650px !important;
} */