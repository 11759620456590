// main: base.less

//styles for content:
address, #floating-persona {
	span, a, strong {
		display:block;

		span {
			display:inline;
			font-weight:bold;
		}
	}
	> span, strong {
		margin: 5px 0;
	}

	a {
		.underline;
	}
}

#close-address {display:none;}
body.active-address {

	// > header, > footer, .goUp, .indentify + .container-fluid, form, .indentify > .container-fluid p, h1 {opacity:0.5;}
	> header, > footer, .goUp, .indentify + .container-fluid, form#beraterteam, .indentify > .container-fluid p, .indentify > .container-fluid ul, .indentify > .container-fluid ol, h1 {opacity:0.5;}
	//.indentify .container-fluid .container-fluid {opacity:1;}
	.floating-persona * {opacity:1 !important;}

	address {
		opacity:0.5;
		img {
			opacity:0.5;
		}
	}

	address.active {
		opacity:1;
		
		.content {opacity:0;}

        .img-div {
            &.flag-aut,
            &.flag-che {
                &::after {
                    display: block;
                }
            }
        }

		.img-js-opener {
			cursor:pointer;
            border: 1px solid @green;
            position: relative;
            display: inline-block;

            &::before {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                right: -1px;
                height: 36px;
                width: 36px;
                background-color: @green;
                background-image: url("../img/info-icon-white.svg");
                background-position: center;
                background-size: 20px;
                background-repeat: no-repeat;
            }
		}
		img {
			opacity:1;
			z-index:10;
		}


	}
	#close-address {
		display:block;
		height: 9999px;
		position: fixed;
		top:0;
		left:0;
		width: 100%;
		z-index: 9;
	}

    .flag-aut,
    .flag-che {
        &::after {
            display: none;
        }
    }
}
body:not(.active-address) {

    address .img-js-opener {
        position: relative;
        transition: border-color 150ms ease-in-out;
        display: inline-block;

        &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: 0;
            right: 0;
            height: 36px;
            width: 36px;
            background-color: @green;
            background-image: url("../img/info-icon-white.svg");
            background-position: center;
            background-size: 20px;
            background-repeat: no-repeat;
        }
    }
	address .img-js-opener:hover {
		cursor:pointer;
		cursor:help;
        border-bottom: 1px solid @green;
	}
}

.profile {
	display:none;
}

#floating-persona {
	position:absolute;
	z-index:10;
	width:100%;
	margin:0;
	height:0;
	padding:0 30px;
	@media screen and (min-width: @screen-sm) {
		padding:0 70px;
	}
	@media screen and (min-width: @bp-indent) {
		padding:0;
		//margin-left:7px;
        padding-left: 15px;
	}
	
	> div.container-fluid {
		position: relative;
		background:@greyLighter;
		margin:10px auto 0;
		padding:30px 15px;
		max-width:1235px;
		border-bottom: 10px solid @white;
	}
}

//postal type address:
.address {
	.name {
		font-weight:bold;
		margin-bottom:5px;
	}
	.numbers {
		margin-top:5px;
	}
}

//postal type address:
.footer address p a {
	display: inline-block;
}

//Highlight kachel
.profile-highlight {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;

    .profile-left {
        flex: 1 0 auto;
        margin-bottom: 20px;
        width: 100%;
        max-width: 835px;
    }

    address {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    @media screen and (min-width: @screen-lg) {
        margin-bottom: 50px;
        flex-direction: row;
        align-items: unset;

        .profile-left {
            width: auto;
            margin-bottom: 0;
            position: relative;

            .embed-responsive {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                height: auto;
                padding-bottom: 0;
            }

            .embed-responsive-item {
                object-fit: cover;
            }
        }

        address {
            height: 100%;
        }
    }


    .profile-right {
        min-height: 100%;
        background-color: @greyLighter;

        .content {
            padding: 15px 20px;
        }
    }
}

address {
    .img-div {
        border-bottom: 1px solid @greyLight;
    }
    &.searched {
        div[class^='flag-'], div[class*=' flag-']{
            &::after {
                right: 0;
            }
        }
        @media screen and (min-width: @screen-lg) {
            .img-div {
                position: relative;
                display: block;
                height: 0;
                padding: 0;
                overflow: hidden;
                padding-bottom: 56.25%;

                img {
                    position: absolute;
                    top: 0;
                    left: -26%;
                    bottom: 0;
                    height: 100%;
                    width: 100%;
                    border: 0;
                }
            }
        }
    }
}

.flag-aut,
.flag-che {
    position: relative;
    &::after {
        position: absolute;
        content:"";
        bottom: 0;
        right: 45px;
        width: 40px;
        height: 27px;
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.flag-aut {
    &::after {
        background-image: url("../img/flag-aut.svg");
    }
}
.flag-che {
    &::after {
        background-image: url("../img/flag-che.svg");
    }
}

.categorie-list {
    margin-top: 10px;

    li {
        display: inline-block;
        margin-bottom: 4px;

        span {
            display: inline-block;
            padding: 2px 10px;
            //background-color: rgba(#d7d7d7, .5);
            background-color: rgba(215, 215, 215, .5);
            border-radius: 15px;
            font-size: 12px;
        }
    }


    //Auskommentiert, da Live zu viele Kategorien pro Person ausgegeben werden.
    // Wenn das wieder einkommteriert werden sollte, bitte in Ausgabe die Liste .categorie-list wieder innerhalb des div.img-div
    //@media screen and (min-width: @screen-lg) {
    //    position: absolute;
    //    bottom: 0;
    //    right: 0;
    //    text-align: right;
    //
    //    li {
    //        display: block;
    //    }
    //}
}

//Utilitys

.mt-5 {
    margin-top: 3rem;
}

.row-beraterteam {
    display: flex;
    flex-wrap: wrap;
}

.search-beraterteam {
    display: flex;
    flex-direction: column;
    .content {
        display: flex;
        flex-direction: column;
    }
}

//Buttons auf selber Höhe
address.searched {
    display: flex;
    flex-direction: column;
    height: 100%;

    .content {
        height: 100%;

        form {
            margin-top: auto !important;
            margin-bottom: 30px !important;
        }
    }
}