.image-wrapper{
  background: #ECECEE;
  overflow: hidden;
  position:  relative;

  &:after {
    display: block;
    content: '';
  }

  img{
    position: absolute;
    top:-100%;
    left:0;
    right: 0;
    bottom:-100%;
    margin: auto;
  }

  &-56{
    &:after{
      padding-top: 56.0%;
    }
  }
}