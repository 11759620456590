// main: base.less


#main form, form {
	margin:0;
	padding:0;

	p{
		margin-bottom: 15px;
	}

	&.border-both {

		&:before, &:after {
			content: "";
			height:1px;
			display:block;
			.bg(@greyLight);
			margin:0;
			
			@media screen and (min-width: @screen-sm) {
				margin:0 0 0 -15px; // NB not on RIGHT.
			}
		}
		&:before { margin-bottom:15px; }
		&:after { margin-top:15px; }
	} //end .border-both


	fieldset {
	  	&.special{
		  display: none;  // Honeypot
		}
		border:none;
	}


	input, .form-control, input[type="date"], textarea, input[type="email"] {
		// border:none;
		 .border;
		.fontize16;
		 border-radius: 0;
		 -moz-appearance: none;
		 -webkit-appearance: none;
		// .border;
		
		outline:none;
		outline:0;
	}

	.input-group * {border-radius: 0;}

	.placeholder() {
		color:@greyMid !important;
		opacity:1;
	}
	input::-webkit-input-placeholder { .placeholder;	}
	input:-moz-placeholder {	.placeholder; } // Firefox 18-
	input::-moz-placeholder { .placeholder; } // Firefox 19+
	input:-ms-input-placeholder { .placeholder; }
	textarea::-webkit-input-placeholder { .placeholder;	}
	textarea:-moz-placeholder {	.placeholder; } // Firefox 18-
	textarea::-moz-placeholder { .placeholder; } // Firefox 19+
	textarea:-ms-input-placeholder { .placeholder; }
	
	.input-group > input.form-control, 
	input.form-control {
		 height: 30px;
		padding: 0 5px;
	}
	.input-group-btn button {
		height: 30px;
	}
	
	textarea {
		resize: vertical;
		min-height:35px;
		.unit;
		.fontize(0.8rem);
		padding:5px;

	}
	
	.form-control:focus {
		//box-shadow: 0 1px 1px rgba(79, 162, 147, 0.4) inset;
		box-shadow: none;
		.border;
	}




}

.navbar form {
	input[type="submit"], button[type="submit"] {
		 border-radius: 0;
		border-radius: 0 10px 0 0;
		background-color:@greyLighter !important;
		.border;
		color:transparent;
		text-indent:-9999px;
		background-position:center center;
	}
}


//bootstrap HOOK (outrank)
.navbar-form .input-group > .form-control {
	width:100%;

	@media screen and (min-width: @bp-desk-min) {
		width:144px;
	}
	@media screen and (min-width:  @bp-indentify) { //was 925px
		width:258px;
	}
}

// all main content forms

#main form {
	input, form-control, input[type="date"] {
		height:35px;
		vertical-align:middle;
		padding:0 5px;
	}

}



//min form under picture
form#berater {
	.margin-top;
	
	.margin-bottom;
	@media screen and (min-width: @screen-sm) {
		margin-bottom:0;
	}

	@media screen and (min-width: @bp-desk-min) {
		margin-top:0;
		.bg;
		.padding;
	}
	
	input[type="text"] {
		.fontize16;
		padding:0 5px 0 10px;
		border:none;
		.bg(@greyLight);
		margin-right:15px !important;
		color:@greyDark !important;
		/*
		@media screen and (min-width: @screen-sm) and (max-width: 1240px) {
			width:110px;
		}
		@media screen and (max-width: 400px) {
			width:110px;
		}
		*/
	}
	.placeholder2() {
		color:@greyDark !important;
	}
	::-webkit-input-placeholder { .placeholder2;	}
	input:-moz-placeholder {	.placeholder2; } // Firefox 18-
	::-moz-placeholder { .placeholder2; } // Firefox 19+
	:-ms-input-placeholder { .placeholder2; }


	.input-group, input[type="text"], button {
		margin:0;
		height:35px;
		line-height:35px;
		vertical-align:middle;
	}	

	p {
		margin-bottom:0;
	}
	
	
	input[type=text], button {
		margin-top:15px;
	}
	
	// force inline floaty form bits.
	@media screen and (min-width: 887px) and (max-width: 1127px) {
		.input-group {
			width:100%;
			float:left;
			.clearfix;
			clear:both;
		
			input[type=text] {
				float:left; width:46%;
			}
			 button {
				float:right; width:46%;
				 text-align:center;
				 padding:0;
			}
			
			&:after {
				content:"";
				display:block;
				clear:both;
				height:15px;
			}
		}
	}
}

form#beraterteam, form#produckt-suchen {

	input {
		width:100%;
		padding:0 5px;
	}
	button:not(.selectpicker), input {
		margin:0;
		height:36px;
		line-height:36px;
		vertical-align:middle;
	}
}

form#-suchen {
	fieldset {
		display:inline;
	}
}

form#seminar-suchen {
	//margin-left:15px;
	
	fieldset {
		display:inline;
		padding:0 15px 0 0;
		height:36px;
		line-height:36px;
		vertical-align:middle;
		
		margin-top:15px;
		display:block;
		@media screen and (min-width: 576px) {
			margin-top:0;
			display:inline;
		}
	}
	
	.custom-breaker {
			display:none;
		
		@media screen and (min-width: 576px) and (max-width: 1087px) {
			display:block;
			height:15px;
		}
	}
	
	.bootstrap-select > .btn {width:auto;}
	
	label {
		font-weight:normal;
		height:36px;
		line-height:36px !important;
		vertical-align:middle;
		.fontize(0.8rem);
	}
}



form#kontakt, form.form-kontakt, form.form-standard {
	
	fieldset, .expando {
		margin:@margin 0;
		padding:0;
	}
	
	input, selectpicker, .bootstrap-select > .btn, textarea {
		width:100%;
		// max-width:335px;
	}

	label {
		font-weight:normal;
		font-size:0.8rem;
		
		span {
			display: block;
			position: absolute;
			margin-left: 30px;
			margin-top: -25px;
		}
	}
	
	.expando button {
		.fontize(0.8rem);
		height:35px;
		//line-height:35px;
		vertical-align:middle;
		margin:0;
		//background-position:10px 0;
	}

}


form#newsletter {
	input, select, .bootstrap-select > .btn {
		width:100%;
	}
}

form{
  .btn-file{
    cursor: pointer;
    position: relative;
    overflow: hidden;

    input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }

  }
}

label.parsley-error{
  color: @red;
}

.upload-wrapper{

  fieldset{

    .button-file-wrapper{
      &.parsley-error{
        border-color:@red;
        outline: 1px solid @red;
      }
    }

    > input{
      margin-bottom: 10px;
    }

    p{
      .fontize16;
      margin-bottom: 5px!important;
      &.error{
        color: @red;
        margin: 0!important;
        padding-top: 5px!important;
      }

    }

    &:first-child{

    }

    padding-bottom: 10px!important;
    border-bottom: 1px solid @greyLight!important;;
  }
}

.file-hint{
  overflow: hidden;
  .fontize16;
  line-height: 35px;
  text-align: left;
}


.success{
  color: @green;
}

.checkbox-headline{
	margin-bottom: 5px!important;

	& + fieldset{

		.checkbox-inline{
			margin-right: 5px;
			padding-left: 0;
		}

		margin-top: 5px!important;

		& + p:not(.checkbox-headline){
			margin-top: -15px!important;;
		}
	}
}


label.checkbox-longtext{
	width: 100%;
}

.checkbox-longtext{
	.icheckbox, .iradio{
		float: left;
	}

	.text{
		margin-left: 27px;
		overflow: hidden;
	}
}