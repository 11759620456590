button, .button, button, .button {
    display:inline-block;
    .boxGreen;
    .grad-green;
    .fontize24;
    border:none;
    border-radius:0;

    // 2020 fix mulitline: SCIL-159
    // height: 35px;
    min-height: 35px;

    line-height: 35px;
    vertical-align: middle;
    .padding-lr;
    outline:none;
    outline:0;

    text-decoration:none;
    &:hover, &:active, &:focus {
        text-decoration:none;
        .blue;
    }

      &:visited{
      .white;
    }


      &.button-small{
      font-size: 0.8rem;
    }

}

.button-file-wrapper{
  .clearfix;
  .border;
  text-align: right;
  .button-file{
    margin: -1px;
  }

  .button-file-text{
    .fontize16;
    overflow: hidden;
    height: 33px;
    line-height: 33px;
    text-overflow: ellipsis;
    padding: 0 5px;
    white-space: nowrap;
    text-align: left;
  }
}



.button-blue {
    .button;
    .gradient;
    height: 35px;
    line-height: 35px;
    vertical-align: middle;

    &:hover, &:active, &:focus {
        .green;
    }
}

body >footer {
    button.expando, .expando button {
        .bg;
        .border;
        background-position: -10px -10px;
        text-indent:-9999px;
        width:22px;
        height:20px;
        min-height:20px;
    }
}
form button.grey-expando {
    //margin:0.35em 0.625em 0.75em; //to match bootstrap fieldset
    background:none;
    .bg;
    .greyDark;
    height:auto;
    position:relative;
    width:100%;
    //max-width:305px; //for padding
    text-align:left;

    &:after {
        content: " ";
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid @greyMid;
        margin-left: 2px;
        position:absolute;
        right:10px;
        margin-top:7px;

        //left:15px;
    }
}
form button.grey-expando.active {

    &:after {
        margin-top:9px;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid @greyMid;
    }
}

form button, form input[type="submit"] {
    border:none;
    .fontize24;
    .grad-green;
}


.greeny {
    .grad-green;
}

.bluey {
    .gradient;
}

