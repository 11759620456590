// main: base.less

.shorten-list {

	+button.load-more {
		display:block;
		width:100%;
		position: relative;
		background-position: center center;
		will-change: transform, background;
		transition: background 0.2s linear;
		&:hover {
			background-color: @green;
			background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20id%3D%22arrow-Ebene_1%22%20xml%3Aspace%3D%22preserve%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20enable-background%3D%22new%200%200%2040%2040%22%3E%0A%20%20%3Cpath%20d%3D%22m17.594%2C27.729c-0.307%2C0%20-0.614%2C-0.112%20-0.856%2C-0.339c-0.503%2C-0.473%20-0.528%2C-1.264%20-0.055%2C-1.767l4.905%2C-5.222l-4.859%2C-4.664c-0.498%2C-0.478%20-0.514%2C-1.269%20-0.036%2C-1.767c0.479%2C-0.5%201.269%2C-0.516%201.768%2C-0.036l6.644%2C6.375l-6.598%2C7.024c-0.248%2C0.264%20-0.58%2C0.396%20-0.913%2C0.396z%22%20transform%3D%22rotate%2890%2C%2020.7246%2C%2020.6562%29%22%20fill%3D%22%23fff%22%20id%3D%22svg_1%22/%3E%0A%3C/svg%3E');
		}
		
		&:before {
			content:"";
			display:block;
			height:80px;
			top:-80px;
			left:0;
			width:100%;
			background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.47) 47%, rgba(255,255,255,1) 100%);
			position: absolute;
			
		}
	}
	
}

.js .shorten-list {
	&:not([data-items-init]) >*:nth-child(3) ~ * {display:none;}
	
	&[data-items-init="1"] >*:nth-child(1) ~ * {display:none;}
	&[data-items-init="2"] >*:nth-child(2) ~ * {display:none;}
	&[data-items-init="3"] >*:nth-child(3) ~ * {display:none;}
	&[data-items-init="4"] >*:nth-child(4) ~ * {display:none;}
	&[data-items-init="5"] >*:nth-child(5) ~ * {display:none;}
	&[data-items-init="6"] >*:nth-child(6) ~ * {display:none;}
	&[data-items-init="7"] >*:nth-child(7) ~ * {display:none;}
	&[data-items-init="8"] >*:nth-child(8) ~ * {display:none;}
	&[data-items-init="9"] >*:nth-child(9) ~ * {display:none;}
	&[data-items-init="10"] >*:nth-child(10) ~ * {display:none;}
}