// main: base.less

html {font-size:17px !important;}

h2, h1.h2, h3.h2 {font-size:2rem;} 
h3, h1.h3, h2.h3 {font-size:1.2rem;} 
			
ul.nav ul li a {font-size:1rem !important;} 



.unitLight() {
	font-family:'Unit Rounded Offc W02 Light'; // http://www.fonts.com/font/fontfont/ff-unit-rounded-offc/light/web-font
}
.unitRegular() {
	font-family:'Unit Rounded Offc W01 Regular';  // http://www.fonts.com/font/fontfont/ff-unit-rounded-offc/regular/web-font
}

// aliases
.unit() {
	.unitLight();
}
.bodyText() {
	.unitLight();
}

/* font-sizes: */
.fontize(@size:1rem, @lh:normal) {
	font-size: @size;
	line-height:@lh;
}



//@fsBody:20px; @lhBody:27px;
@fsBody:1rem; @lhBody:1.35rem;
.fontizeBody() {.fontize(@fsBody, @lhBody);}
body, p, td, li {.fontizeBody;}

// @fsH1:60px; @lhH1:60px;
@fsH1:3rem; @lhH1:3rem;
.fontizeH1() {.fontize(@fsH1, @lhH1);}
.h1, #main .h1, .fontize60 {
	.fontizeH1;
}

//@fsH2:50px; @lhH2:50px;
@fsH2:2.5rem; @lhH2:2.5rem;
.fontizeH2() {.fontize(@fsH2, @lhH1);}
.h2, #main .h2, .fontize50 {.fontizeH2;}

//@fsH3:30px; @lhH3:28px;
@fsH3:1.5rem; @lhH3:1.4rem;
.fontizeH3() {.fontize(@fsH3, @lhH3);}
.h3, #main .h3, .fontize30 {.fontizeH3;}

//@fsH4:24px; @lhH4:31px;
@fsH4:1.2rem; @lhH4:1.55rem;
.fontizeH4() {.fontize(@fsH4, @lhH4);}
.h4, #main .h4, .fontize24 {.fontizeH4;}

//@fsH5:18px; @lhH5:23px;
@fsH5:0.9rem; @lhH5:1.15rem;
.fontizeH5() {.fontize(@fsH5, @lhH5);}
.h5 {
	.fontizeH5; 
	.unitRegular;
}
.fontize36 {.fontize(1.8rem, 1.8rem);}
.fontize24 {.fontizeH4;}
.fontize18 {.fontizeH5;}

//@fsH6:16px; @lhH6:26px;
@fsH6:0.8rem; @lhH6:1.3rem;
.fontizeH6() {.fontize(@fsH6, @lhH6);}
.h6, .fontize16 {.fontizeH6;}




.fontizeNav()  {.fontize18;}
.fsNav() {.fontize18;}


//  JS EQUIV:  <script type="text/javascript" src="http://fast.fonts.net/jsapi/d007e022-120c-467a-862e-7c8bd551fc4f.js"></script>



body {
	.unitLight;
	.fontizeBody;
}

p, th, .th, td, select, input, button {
	.unit;
	.fontizeBody;
}

//reset marg/padd 
h1, h2, h3, h4, h5, h6 {
	margin:0; padding:0;
}



h1, h2, h3, h4, h5 , h6, {
	.unit();
}


h1, .h1 {.fontizeH1();}
@media screen and (max-width: @screen-xs) {
	h1, .h1 {.fontize(2.2rem, 2.2rem);}
}

h2, .h2 {.fontizeH2();}
h3, .h3 {.fontizeH3();}
h4, .h4 {.fontizeH4();}
h5, .h5 {
	/*
	.unitRegular();
	.fontizeH5();
	*/
	.fontizeH4();
	font-weight:bold;
}
/*
h6, .h6 {.fontizeH6();}
h7, .h7 {.fontizeH7();}
*/
/*
nav li {
	.fontizeNav();
}
*/


.color-inherit, a.color-inherit {
	color: inherit;
}

h3.h3 >a {
    &:visited { 
	    color: inherit;
    }
    &:hover, &:focus {
        color:#4fa293;
        text-decoration: none;
    }
}
