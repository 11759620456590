// main: base.less

.pagination-wrapper{
  margin-top: 30px;
}

section.pager { //.div
	display:inline;
	float:right;
	margin:0;

	header {
      display: inline-block;
      vertical-align: middle;
      height: 34px;
      line-height: 34px;
      margin-right: 20px;
	}
	
	ul.pagination {
		margin:0;
		float: right;
		li {

          &:first-child{
            a{
              margin-left: 0;
            }
          }

			a {
                .margin-left;
				.bg(@greyLight);
				display:block;
				min-width:35px;
				//height:35px;
				vertical-align:middle;
				text-decoration:none;
				.greyDark;
				border:none;
				border-radius:0;
				
				&:hover {
					text-decoration:none;
					.bg(@blue);
					.white;
				}
			}
			
			
			&.active {
				a {
					.bg(@green);
					.white !important;			
				}
			
			}
		}
	
	}
}

.tracker {
	height: 34px;
  	line-height: 34px;
	@media (min-width: @screen-xs) {
		float:left;
	}
}


// Canadian style:
/*
.page-navigation
	>p
	> ul.f3-widget-paginator
	>li.current
	>li a
*/
.page-navigation {
	.clearfix;
	
	p {
		display: inline;
		float: left;
		margin: 0;
	}
	ul {
		display: inline;
		float: right;
		margin: 0;
	}
		
	li {
		display: inline;
		.margin-left;
		position: relative;
		float: left;
		margin-bottom:15px; // (for small sizes)

		&.current {
			.bg(@green);
			.white !important;
			padding: 5px 14px;
			display:inline-block;
			position: relative;
			float: left;
			line-height: 1.42857143;
			// margin-left: 15px;
		}

		a {
			// .margin-left;
			.bg(@greyLight);
			display:block;
			min-width:35px;
			//height:35px;
			vertical-align:middle;
			text-decoration:none;
			.greyDark;
			border:none;
			border-radius:0;
			padding: 5px 14px;
			line-height: 1.42857143;

			&:hover {
				text-decoration:none;
				.bg(@blue);
				.white;
			}
		}	
			
		
	}
}
// margin it:
.page-navigation +.news-clear {
	margin-top:30px;
}
