#main {

	.columns-2 {
		//padding-top:30px;
		//padding-bottom:30px;

		.item {
			margin-bottom:30px;  //needed instead of h2.h3 margin-top.

			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid;
		}

		li {

			a.icon-down {
				background-position: -10px center;
				padding-left: 25px;
			}
			span {
				display:block;
				padding-left: 25px;
			}

		}

		h2, h3, h4, h5, h6 {
			border:none;
		}
		h2.h3 {
			margin-top:0;
			.margin-bottom;
		}

		&.downloads {

			.collapse-body {
				padding-bottom: 16px;
			}

			.collapse-arrow {
				cursor: pointer;
				position: relative;
				padding-right: 50px !important;

				&:before {
					display: block;
					position: absolute;
					top: 50%;
					right: 13px;
					transform: translateY(-50%);
					content: "";
					height: 30px;
					width: 30px;
					background-image: url('../img/chevron-up.svg');
					background-repeat: no-repeat;
					background-size: 30px 30px;
				}

				&.collapsed {
					&:before {
						transform: translateY(-50%) rotate(-180deg);
					}
				}
			}

			.item {
				background-color: lighten(#000, 92%);
				margin-bottom: 17px;
				display: inline-block;
				width: 100%;

				h3 {
					padding: 16px;
					font-weight: 700;
				}
			}
			li {

				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}

				a.icon-down {
					background-position: -10px -7px;
					padding-left: 25px;
					display: inline-block;
				}
				span {
					display:block;
					font-size: 14px;
				}
			}

			.categorie {
				background-color: lighten(#000, 80%);
				display: block;
				padding: 4px 16px;
				margin-bottom: 9px;
				font-weight: 700;
			}

			//HINWEIS TEXT
			.collapse-message {
				display: flex;
				padding: 16px;
				background-color: #abd9d5;
				align-items: center;

				img {
					margin-right: 8px;
				}

				p {
					margin-bottom: 0;
				}

				+ .collapse,
				+ .collapsing {
					.collapse-body {
						padding-top: 16px;
					}
				}

				+ .collapse-message {
					padding-top: 0;
				}
			}
		}
	}
}

.download-list{
	padding-left: 16px;
	padding-right: 16px;

	.icon-down{
		background-position: -10px center;
		padding-left: 25px;
	}
}

// make open dropdowns always on top.
.dropdown-menu.open {
    z-index: 99999 !important;
}
