/* CSS Document */

.glossar-letter {
	text-transform: uppercase;
	font-weight: normal !important;
	color: #969696;
	font-size: 1.8rem;
	line-height: 1.8rem;
}

.glossar-item {
	
	margin-bottom: 30px;
	
	padding-left: 0;
	padding-right: 0;
		
	@media screen and (min-width: @screen-sm-min) {
		&:nth-of-type(2n+1) { padding-left: 0; padding-right: 10px; }
		&:nth-of-type(2n+2) { padding-left: 10px; padding-right: 0; }
	}
	
	
	> div {
		padding-bottom: 30px;
		border-bottom: 1px solid @greyLight;
		
		padding-left: 15px;
		padding-right: 15px;
	}
	
	@media screen and (min-width: @screen-sm-min) {
		&:nth-of-type(2n+1) > div { padding-left: 15px; padding-right: 0; }
		&:nth-of-type(2n+2) > div { padding-left: 0; padding-right: 15px; }
	}
	
	h4 {
		padding-bottom: 10px!important;
	}
	p {
		margin-bottom: 10px!important;
	}
	a {
		
	}
}

.accordion {

	> div {
		padding-left: 0;
		padding-right: 0;
			
		@media screen and (min-width: @screen-sm-min) {
			&:nth-of-type(2n+1) { padding-left: 0; padding-right: 10px; }
			&:nth-of-type(2n+2) { padding-left: 10px; padding-right: 0; }
		}
		
		
		> div {
			padding-bottom: 30px;
			border-bottom: 1px solid @greyLight;
			
			padding-left: 15px;
			padding-right: 15px;
		}
		
		
		@media screen and (min-width: @screen-sm-min) {
			&:nth-of-type(2n+1) > div { padding-left: 15px; padding-right: 0; }
			&:nth-of-type(2n+2) > div { padding-left: 0; padding-right: 15px; }
		}
	}
	
	.glossar-item {

		padding-left: 15px;
		padding-right: 15px;

		> div { border-bottom: none; padding-bottom: 0; padding-left: 0; padding-right: 0; }
		
		a { 
			text-decoration: none; 
			h4, p { .greyDark(); }
			
			&:hover, &:focus {
				h4 { .green(); }
				span { text-decoration: underline; }
			}
		}
		
		div.visible-collapsed {
			height: 2 * 22.95px;
			overflow: hidden;
		}
		
		a.collapsed > .visible-collapsed {
			display: block;
			visibility: visible;
			opacity: 1;
		}
		
		a > .visible-collapsed {
			display: none;
			visibility: hidden;
			opacity: 0;
			transition: opacity .3s;
		}
		
		/*
		span.more:after {
			position: absolute;
			display: inline-block;
			margin: 1px 0 0 2px;
			content: " >";
			text-decoration: none !important;
		}
		*/

		.caret:after {
			content: "";
			display: block;
			margin: -6px 0 0 -4px;
			width: 0;
			height: 0;
			border-left: 4px solid transparent;
			border-right: 4px solid transparent;
			border-top: 4px solid #fff;
		}

		.caret-up {
			border-top: none;
			border-bottom: 4px solid;

			&:after {
				border-top: none;
				border-bottom: 4px solid #fff;
				margin: 2px 0 0 -4px;
			}
		}
	}
}



