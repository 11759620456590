// main: base.less

body {
	-ms-overflow-style: scrollbar;
}

body > footer {
	
	.lt-ie8 & {
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35788b', endColorstr='#013588',GradientType=0 ); /* IE6-8 */
		
	}
	
}

.ie { //first for all .ie (JS detect)
	//	aside.sidebar:hover {
	//		margin-right:16px;
	//	}
	body {
		-ms-overflow-style: scrollbar;
	}

	//wierd behaviour freom affix sidebar: so stop it for smaller height screens.
	 @media screen and (max-height: 900px) {
		 aside.sidebar.affix {
			position:absolute !important;
		}
	 }


	.navbar-form .input-group {
		margin-right:10px;
	}

	.navbar-toggle {
		margin-right:30px;
	}
	
}
.ie9, .lt-ie9 { // then correct for older IE
		aside.sidebar:hover {
			margin-right:0;
		}
	
	button[type="submit"], button {
		filter:none;
	}
}
.ie:not(.ie11)  {
	.img-responsive, .thumbnail > img, .thumbnail a > img, .carousel-inner > .item > img, .carousel-inner > .item > a > img {
		width:inherit;
	}
}

// /no-svg / no-js.
.no-svg, .no-js {
	.navbar form input[type="submit"], .navbar form button[type="submit"] {
		background-position: -8px -6px !important;
	}
	.icon-arrow, .no-svg .icon-arrow\:regular {
		background-position: -10px -5px;
	}
	
	.dropdown-toggle.icon-net {
		background:none; 
	}

}


.ipad.landscape {
	#main .carousel h1 {
	  font-size: 3rem;
	}
}
.ipad.portrait {
	#main .carousel h1 {
	  font-size: 2.5rem !important;
	}
}
