// main: base.less

// main: base.less

nav.tools {
	margin-top:50px;
	.border-top;
	.border-bottom;
	text-align:left;
	@media screen and (min-width: 615px) {
		text-align:center;
	}
	
	padding:22px 0 22px 22px;
	/*
	@media screen and (min-width: 2709px) {
		padding:22px 0 22px 15px;
	}
	*/
	
	li.fake, li.fake.active {display:none !important;}
	

	ul {
		.clearfix;
		text-align:left;
		@media screen and (min-width: 615px) {
			//text-align:center;
			display:inline-block;
		}
	}


	li, li.active {
		
		//height:50px;
		background-position:center left;
		//margin: 0 15px 0 0;
		//margin: 0 30px 0 0;
		
		display:block;
		float:none;

		background-size:50px 50px; // make all icons bigger
		&.icon-piggy {
			background-size:53px 53px;
		}
		&.icon-beraterteam {
			background-size:45px 45px;
		}
		
		@media screen and (min-width: 615px) {
			margin:0;
			float: left;
			width: 48%;
		}
		@media screen and (min-width: 999px) {
			width: 32.5%;
		}
		
		@media screen and (min-width: @bp-indent) {
			// margin: 0 15px 0 0;
			display:inline-block;
			width:inherit;
		}

		a { .greyDark;
			display:inline-block;
			/*
			min-height:50px;
			line-height:50px;
			*/
			//vertical-align:middle;
			width: auto;
			//padding-left:50px;
			//padding-right:35px;
			padding: 25px 30px 25px 50px;
			margin-right:20px;
			@media screen and (max-width: 615px) {
				white-space: nowrap;
			}


			background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22arrow-Ebene_1%22%20xml%3Aspace%3D%22preserve%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20enable-background%3D%22new%200%200%2040%2040%22%3E%09%3Cpath%20d%3D%22m17.594%2027.729c-0.307%200-0.614-0.112-0.856-0.339-0.503-0.473-0.528-1.264-0.055-1.767l4.905-5.222-4.859-4.664c-0.498-0.478-0.514-1.269-0.036-1.767%200.479-0.5%201.269-0.516%201.768-0.036l6.644%206.375-6.598%207.024c-0.248%200.264-0.58%200.396-0.913%200.396z%22%20fill%3D%22%234E9F92%22%2F%3E%3C%2Fsvg%3E');
			background-repeat:no-repeat;
			background-position:center right;

			&:hover {
				text-decoration:none;
				.green;
			}
		}
	
	}
	//padding adjust for tricksy icons
	li.icon-piggy, li.icon-shop {
		a {padding-left:55px;}
	}


/*
	.lavalamp-object { //hide for MOBS
		@media screen and (max-width: 615px) {
		  border-bottom:none !important;
		}
	}
	*/
	


}
