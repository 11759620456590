// main: base.less

.carousel {
	margin-left:@indent;
	margin-right:-@indent;
	position:relative;
	margin:0 auto 0 auto;


	.item {

		.bg {

			img {
			}
		}
		button {
			.grad-green;
			height:55px;
			line-height:55px;
			padding:0 20px;
			.margin-top;
		}
	}
}

/*
.carousel:hover {
	.slidesjs-previous, .slidesjs-next {
		.rgba(255, 255, 255, 0.8); //background white alpha
	}
}
*/

.slidesjs-previous, .slidesjs-next {
	display:block;
	font-size:1px;
	font-size:0;
	text-indent:-9999px;
	width:40px;
	height:40px;
	background-repeat:no-repeat;

	position:absolute;
	top:50%;
	margin-top:-20px; //to center
	z-index:2000;

}
.slidesjs-previous {
	left:10px;
	background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22left-Ebene_1%22%20xml%3Aspace%3D%22preserve%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20enable-background%3D%22new%200%200%2040%2040%22%3E%09%3Cpath%20d%3D%22m24.927%2031.182l-11.25-9.742c-0.219-0.189-0.345-0.466-0.345-0.756s0.126-0.566%200.345-0.756l11.25-9.741c0.297-0.258%200.716-0.318%201.07-0.153%200.356%200.162%200.585%200.518%200.585%200.909v19.483c0%200.392-0.229%200.747-0.585%200.909-0.133%200.062-0.274%200.091-0.415%200.091-0.236%200-0.47-0.084-0.655-0.244z%22%20fill%3D%22%23A2A1A1%22%2F%3E%3C%2Fsvg%3E'); background-repeat: no-repeat;
}
.no-svg .slidesjs-previous {
	background-image:url('../img/icons.png');background-position:0 -440px; background-repeat: no-repeat;
}

.slidesjs-next {
	left:auto;
	right:10px;
	@media screen and (min-width: @bp-desk-min) and (max-width: 1390px) { //was max-1280
		right:65px;
	}
background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22right-Ebene_1%22%20xml%3Aspace%3D%22preserve%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20enable-background%3D%22new%200%200%2040%2040%22%3E%09%3Cpath%20d%3D%22m14.165%2031.426c-0.141%200-0.283-0.029-0.416-0.091-0.356-0.162-0.584-0.518-0.584-0.909v-19.484c0-0.392%200.229-0.747%200.584-0.909%200.354-0.165%200.773-0.104%201.07%200.153l11.251%209.741c0.219%200.189%200.345%200.466%200.345%200.756s-0.126%200.566-0.345%200.756l-11.251%209.742c-0.185%200.161-0.419%200.245-0.654%200.245z%22%20fill%3D%22%23A2A1A1%22%2F%3E%3C%2Fsvg%3E');
}
.no-svg .slidesjs-next {
	background-image:url('../img/icons.png');background-position:0 -660px;background-repeat:no-repeat;
}

.slidesjs-pagination {
	position:absolute;
	z-index:200;
	bottom:0;
	display:block;
	width:100%;
	text-align:center;

		//hide for small screens
	display:none;
	@media screen and (min-width: @screen-sm) {
		display:block;
	}

	li.slidesjs-pagination-item {
		display:inline-block;
		font-size:1px;
		font-size:0;
	}

	li.slidesjs-pagination-item a {
		display:inline-block;
		font-size:1px;
		font-size:0;
		text-indent:-9999px;
		padding:10px 5px;

		/*
		display:block;
		font-size:1px;
		font-size:0;
		text-indent:-9999px;
		width:11px;
		height:11px;
		background:red;
		border-radius:100%;
		*/
	}
	li.slidesjs-pagination-item a:after {
		content: "";
		display:block;
		width:11px;
		height:11px;
		.bg(@grey);
		border-radius:100%;
	}
	.slidesjs-pagination-item a.active:after {
		.bg(@green);
	}
}


// show only INIT carousel content (stop it flashing)



#main .carousel {

	//hide mobile1st, e-instate for SM+
	.item, h1, h2, button {display:none;}
}
#main .carousel[style] {
	margin-bottom:20px;
	.item {display:block;} 
	h1, h2 {display:block;}
	button {display:inline-block;}


	//display:block;
	@media screen and (max-width: 259px) {
		display:none !important;
	}
}

#main .carousel {
	
	//hide when its silly small
	//display:block;
	@media screen and (max-width: 259px) {
		display:none !important;
	}

	h1, h2, h2.h3 {
		display:none;
		@media screen and (min-width: @screen-md) {
			display:block;
		}
	}
	h1, h1:first-child {
		.fontize(1.5rem); 
		margin:0;
		padding:0;
		
		//crop for sm
		@media screen and (max-width: @screen-sm) {
			white-space: nowrap;
			overflow:hidden;
			text-overflow: ellipsis;
		}
		
		// bigger forn for sm+
		@media screen and (min-width: @screen-sm) {
			.fontize(2.5rem);
		}
		// bigger forn for md+
		@media screen and (min-width: @screen-md) {
			.fontize(3.5rem);
		}
	}
	.item .content {
		position:absolute;
		top:0;
		width:100%;
		padding:0 70px;
		padding-top:110px; // USER CAN OVERRIDE (?)
		/*
		@media screen and (min-width: @bp-desk-min) {
			padding:0 135px 0 70px;
			padding-top:110px; // USER CAN OVERRIDE (?)
		}
		*/
		
		
		padding:5px 45px 0 45px;
		@media screen and (min-width: @screen-xs) {
			padding:9% 45px 0 45px;
		}
		
		
		@media screen and (min-width: @screen-sm) {
			padding:2% 120px 0 60px;
		}	
		//@media screen and (min-width: @bp-desk-min) and (max-width: 1390px) { //was max-1280
		@media screen and (min-width: 1390px) { //was max-1280
			padding:9% 60px 0 60px;
		}

		// alignment & color:
		@media screen and (min-width: @screen-sm) {
			&.center {	text-align:center;	}
			&.right {	text-align:right;	}
			&.left {	text-align:left;	}
			&.white {	.white;	}
		}
//		&.center, &.left, &.right {
//			@media screen and (max-width: 560px) {
//				text-align:center !important;
//				padding:0 !important;
//			}
//		}
		

		.h1, .h2 {
			//display:none;
			margin-top:10px;
			
			font-size: 1rem;
    		line-height: 1.35rem;
			@media screen and (min-width: 530px) {
				.fontizeH3;
			}
			
//			@media screen and (min-width: 560px) {
//				//display:block;
//				.fontizeH3;
//			}
			@media screen and (min-width:@screen-sm) {
				margin-top:20px;
				.fontizeH2;
			}
		}
		.h3 {
			display:none;
			@media screen and (min-width:@screen-sm) {
				display:block;
			}
		}
		button, a.button {
			height: 35px;
    		line-height: 35px;
			margin:0;
			@media screen and (min-width: 560px) {
				margin:0 auto;
			}
			@media screen and (min-width: @screen-sm) {
				height: 55px;
    			line-height: 55px;
				margin:15px auto 0 auto;
			}
		}

	}
}

.overflowIt() {
	overflow-x: hidden !important;
	overflow-y: visible !important;
}

@media screen and (max-width: @screen-sm) {
	#main .carousel {
		.overflowIt;

		.slidesjs-container {
			// .overflowIt;
			height: 0 !important;
			padding-bottom: 56%;
			// background: Red;
			.item {
				// position:relative !important;
				max-width: calc(100%) !important;
				left:0;
				
				.content {
					position:relative;
					//.vertical-center;
					padding:0 30px;
					height: 100% !important;
				}
			}
		}
	}
}

@media screen and (max-width: 530px) {
	#main .carousel {
		.slidesjs-container {
			padding-bottom: 66%;
		}
	}
}

@media screen and (min-width: 639px) and (max-width: @screen-sm) {
	#main .carousel {
		.slidesjs-container {
			padding-bottom: 52%;
		}
	}
}
