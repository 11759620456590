//Swiper
.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;


	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.swiper {
	width: 100%;
	height: 300px;
	margin-left: auto;
	margin-right: auto;
}

.swiper-slide {
	background-size: cover;
	background-position: center;
}

.mainSwiper {
	height: 80%;
	width: 100%;
}

.lowerSwiper {
	height: 20%;
	box-sizing: border-box;
	padding: 10px 0;
}

.lowerSwiper .swiper-slide {
	width: 25%;
	height: 100%;
	border: 1px solid @greyLight;
}

.lowerSwiper .swiper-slide-thumb-active {
	opacity: 1;
	border: 1px solid @green;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

//Customized

.product-slider {
	@media screen and (max-width: @screen-sm-max ) {
		max-width: 450px;
		margin: 0 auto 30px auto;

	}
}

.mainSwiper {
	.caption {
		display: block;
		margin-top: 6px;
		margin-bottom: 8px;
		text-align: center;
		color: @greyMid;
		text-decoration: none !important;
	}
	.lgm-item:hover {
		cursor: zoom-in !important;
		text-decoration: none;
	}
}

.lowerSwiper {

	.swiper-button-next:after, .swiper-button-prev:after {
		font-size: 20px;
	}

	.swiper-button-prev, .swiper-rtl .swiper-button-next {
		left: 0;
	}

	.swiper-button-next, .swiper-rtl .swiper-button-prev {
		right: 0;
	}

	.swiper-slide {
		cursor: pointer;
	}
}

.swiper-button-next:after, .swiper-button-prev:after {
	font-size: 25px;
}


//Lightgallery
.lg-on {
	#afx,
	.navbar {
		z-index: 1;
	}
}

.lg-container {

	.lg-next, .lg-prev {
		background: transparent;
		border: none;
		color: @green;
	}

	.lg-next:hover, .lg-prev:hover, .lg-close:hover {
		color: @green;
	}

	.lg-backdrop {
		background-color: #fff;
	}

	.lg-outer .lg-thumb-outer {
		background-color: @greyLighter;
	}

	.lg-counter {
		color: @green;
	}

	.lg-close {
		color: @green;
	}

	.lg-sub-html {
		color: @greyMid;
	}
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
	border-color: #4fa293;
}

.lg-download {
	display: none;
}

//Gallery View

#lightgallery {
	.lgm-item {
		position: relative;
	}

	.lgm-item .img-responsive {
		margin: 0 auto;
	}

	.lgm-item .magnifying-glass {
		display: flex;
		justify-content: center;
		align-items: center;
		transition: opacity 200ms ease-in-out;
		height: 40px;
		width: 40px;
		background-color: @green;
		position: absolute;
		right: -5px;
		bottom: -5px;
		border-radius: 100%;
	}

	.lgm-item:hover .magnifying-glass {
		opacity: 0.8;
	}

	.caption {
		display: block;
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: 10px 0 30px;
		text-align: center;
	}
}



