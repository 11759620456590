//HR effect:

#main {

	.hr {
		height:1px;
		.bg(@greyLight);

		margin:0;
		@media screen and (min-width: @screen-sm) {
			//margin:0 0 0 -30px; // NB not on RIGHT.
			margin:0 0 0 -15px; // NB not on RIGHT.
		}
	}
	.hr-margin {
		.hr;
		@media screen { //otheriwse outranked by .hr MQ
			.margin-top;
			.margin-bottom;
		}
	}
	.hr-margin-top {
		.hr;
		@media screen {
			.margin-top;
		}
	}
	.hr-margin-bottom  {
		.hr;
		@media screen {
			.margin-bottom;
		}
	}


	.border-bottom:after, .border-both:after {
		content: "";
		height:1px;
		display:block;
		.bg(@greyLight);
		
		margin:0;
		@media screen and (min-width: @screen-sm) {
			//margin:0 0 0 -30px; // NB not on RIGHT.
			margin:0 0 0 -15px; // NB not on RIGHT.
		}
	}

	.border-before:before,  .border-both:before {
		content: "";
		height:1px;
		display:block;
		.bg(@greyLight);
		
		margin:0;
		@media screen and (min-width: @screen-sm) {
			//margin:0 0 0 -30px; // NB not on RIGHT.
			margin:0 0 0 -15px; // NB not on RIGHT.
		}
	}
	
	

	h2.border-bottom, h3.border-bottom, .h2.border-bottom, .h3.border-bottom {
		padding-bottom:10px;
		margin-bottom:15px; //30px; (there must be some consistency)
		
		&:after {
			margin-top:10px;
		}
	}

}
