// main: base.less

.sliderNav {
	.margin-top(@marginX2);
	.margin-bottom(40px);
	min-height:75px;
	//.border-top;
	//.border-bottom;
	.bg(@white);
	.clearfix;
	
	&:after {
		content: "";
		height:1px;
		display:block;
		.bg(@greyLight);
		
		margin:0;
		@media screen and (min-width: @screen-sm) {
			margin:0 0 0 -30px; // NB not on RIGHT.
		}
	}

	&:before {
		content: "";
		height:1px;
		display:block;
		.bg(@greyLight);
		
		margin:0;
		@media screen and (min-width: @screen-sm) {
			margin:0 0 0 -30px; // NB not on RIGHT.
		}
	}	
	
	
	ul {
		margin:22px 0 22px 0;
	}

	li {
		float: none;
		//border-bottom:@white 3px solid;

		//&:hover { border-bottom:@green 3px solid; }

		a  {
			.greyDark;
			display: inline-block;
			padding:0 15px;
			margin-bottom:10px;
			
			height:40px;
			line-height:40px;
			vertical-align:middle;

			.noUnderline;
			
			&:hover {
				.green;
			}
		}
	}
	li:last-child a {
		margin-bottom:0;
	}
	
}

.sliderNav, .a-z {

	.lavalamp-object {
		display:none;
		//opacity:0;
		//width:100% !important;
	}

	li a {
		border-bottom:3px solid white;
		transition: border 0.6s ease;
	}
	li a:hover {
		border-bottom:3px solid @green;
	}
	li.active a {
		border-bottom:3px solid @green;
	}


	ul:hover {
	li.active a {
		border-bottom:3px solid white;
		color:@grey !important;
		
		&:hover {
			border-bottom:3px solid @green;
			color:@green !important;
			}
		}
	}
}

//CUT OFF FOR HORIZ:
@media screen and (min-width: @screen-sm) {
	.sliderNav {
		
		ul {margin:0 0 0 -15px;}
		li {	
			float:left;
			
			a {
				margin-bottom:0;
				height:75px;
				line-height:75px;
				display: block;
			}
		}
		.lavalamp-object {
			//opacity:1;
			//width:100px !important;
		}
	}
}





.lavalamp-object {
	border-bottom: 3px @green solid;

	/*
	&:after {
	content:"";
		display:block;
		//height:3px;
		width:100%;
		.bg(@red);
		height:0%;
		//padding-bottom:3px;
		border: red solid 2px;
	}
	*/
}






/*
.tools {
	margin-top:50px;

	.lavalamp-object { //hide for MOBS
		@media screen and (max-width: 615px) {
		  border-bottom:none !important;
		}
	}

	ul {
		.clearfix;
	}


	li, li.active {
		//height:50px;
		background-position:center left;
		margin: 0 15px 0 0;


		@media screen and (min-width: 615px) {
		  float:left;
		}

		a,  {
			.greyDark;
			display:inline-block;
			min-height:50px;
			line-height:50px;
			@media screen and (max-width: 615px) {
				white-space: nowrap;
			}
			//vertical-align:middle;
			width: auto;
			padding-left:50px;
			padding-right:35px;

			background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22arrow-Ebene_1%22%20xml%3Aspace%3D%22preserve%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20enable-background%3D%22new%200%200%2040%2040%22%3E%09%3Cpath%20d%3D%22m17.594%2027.729c-0.307%200-0.614-0.112-0.856-0.339-0.503-0.473-0.528-1.264-0.055-1.767l4.905-5.222-4.859-4.664c-0.498-0.478-0.514-1.269-0.036-1.767%200.479-0.5%201.269-0.516%201.768-0.036l6.644%206.375-6.598%207.024c-0.248%200.264-0.58%200.396-0.913%200.396z%22%20fill%3D%22%234E9F92%22%2F%3E%3C%2Fsvg%3E');
			background-repeat:no-repeat;
			background-position:center right;

			&:hover {
				text-decoration:none;
			}
		}

	}
}
*/


div.a-z {
	.margin-bottom(30px);
}

li.fake {
	width:0px !important;
	margin:0 !important;
}
.tools li.fake {
	height:50px;
}
.a-z li, .a-z li.fake {
	// height:30px;
}

.a-z li {
	margin:0 5px 0 0;
}
