header.splash {
	nav {
		border:none;

		li {
			.margin-right;
			float:left;

			a {	display:inline-block;
				position:relative;
				//.fontize(14px);
				.fontize(0.7rem);
				.greyMid;
				//padding: 15px 10px 15px 15px;
			}
		}
	}
}
#main {
	div.splash {

		h1, h2 {
			border:none;
			margin-top:0;
		}
		h2 {
			font-size: 2.1rem;
			line-height: 2.1rem;
		}

		padding:0 30px;
		.margin-bottom(100px);

		img {
			margin: 30px 0 70px 0;
		}

		ul {
			li {

				a {
					//display:inline-block;

					&:after {
						position:absolute;
						display:inline-block;
						margin:1px 0 0 2px;
						content: " >";
						min-width:100px;
						background:#fff;
					}
				}
			}
		}

	}
}

footer.splash {
	.padding;
	min-height:0;
}

ul li.expando {
	.header {
		color:@green;
		cursor: pointer;

		&:after {
			position:absolute;
			display:inline-block;
			margin:1px 0 0 2px;
			content: " >";
		}
	}
	ul {
		margin-left:15px;
		margin-bottom:15px;
	}
}
