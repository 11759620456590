.bg-img {
	background-repeat: no-repeat;
	background-size: 100% auto;
}
.bg-img-bottom {
	background-position: bottom;
}


.bg-seminar {
	background-color: #fff;
	background-image: url('../img/seminare-bg-800.jpg');
	@media screen and (min-width: @screen-md) {
		background-image: url('../img/seminare-bg-1265.jpg');
	}
	
	background-size: 100% auto;
	
	padding-bottom: 12vw;
}