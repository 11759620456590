// main: base.less

// for chat api - Canada
#chat {
	position:absolute;
	right:15px;
	top: 190px;
	z-index: 2001;

	@media screen and (min-width: @screen-sm) {
		right:10px;
		top:15px;
		min-width:215px;
	}

	@media screen and (min-width: @bp-indentify) {
		right:0;
	}


	// margin:12px 0 0 0;
	.clearfix;

	a {
		float:left;
	}


	@media screen and (max-width: 280px) {
		display:none;
	}
}


#google_translate_element {

	position: absolute;

	right: 15px;
	top: 150px;
    z-index: 2000;

	@media screen and (min-width: @screen-sm) {
		right: 250px;
		top: 17px;
	}
}

@media screen and (max-width: @screen-sm) {
	#chat, #google_translate_element {transition:opacity ease 1s;}
	.navbar-collapse.collapse, .navbar-collapse.collapsing {
		#chat, #google_translate_element {opacity:0 !important; z-index:-1 !important;}
	}
	.navbar-collapse.collapse.in  {
		#chat, #google_translate_element {opacity:1 !important; z-index:20001 !important;}
	}
}
