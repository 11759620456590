// main: base.less

.produkt-feature {
	
	h3 {margin-top:0; padding-top:0;}
}

#main .productUL {

	h2 {
		padding-bottom:5px;
		//.border-bottom;
		.margin-bottomX2;
	}
	h3 {
		padding:0;
		.margin-tb;
	}
	a h3 {
		color:@greyDark;
	}


	li {

		a {
		  	overflow: hidden;
		  	max-width: 100%;
			display:block;
			.padding-bottom;

			&:hover {
				.noUnderline
			}

			span {
				display:block;
			}

		  	img{
			  //max-width: 1000%;
			}

			.img {}
			.title {
				.bold;
				.greyDark;
				.noUnderline;
			}
			.more {
				display:inline-block;
				border-bottom:1px @white solid;

				&:after {
					content: ">";
					padding-left:3px;
					margin-top:2px;
					border-bottom:2px @white solid;
				}
			}

			&:hover {
				.noUnderline;
				.more {
					border-bottom:1px @green solid;
				}
				.more:after {
					border-bottom:2px @white solid;
				}
			}
		}
	}
}

.a-z-headline{
  margin-top: 30px!important;
}

ul.a-z {
	margin: -9px -10px 0 -10px;
	.clearfix;

	li {
		// float:left;
		margin:0;

		a {
		  	text-transform: uppercase;
			float:left;
			padding:10px 10px;
		  	.fontize18;
			&:hover {
				.noUnderline;
			}
		}

	  &:first-child{
		a{
		  //padding-left: 0;
		}
	  }

	}

	li.fake {display:none;}
}


ul.a-z.border-tb {
	border: 1px solid @greyLight;
	border-width: 1px 0;
	padding: 15px 10px;
	margin-bottom: 30px;
}

.link-element{
  cursor: pointer;
}

ul.tick {
	li {
		background-repeat:no-repeat;
		background-position: 0 -10px;
		background-repeat: no-repeat;
		min-height: 30px;
		padding-left: 40px;
		background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22tick-Ebene_1%22%20xml%3Aspace%3D%22preserve%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20enable-background%3D%22new%200%200%2040%2040%22%3E%09%3Cpath%20d%3D%22m19.25%2027.375c-0.32%200-0.629-0.123-0.864-0.347l-4.319-4.129c-0.499-0.477-0.517-1.268-0.04-1.767s1.268-0.518%201.768-0.04l3.187%203.046%205.697-9.53c0.354-0.593%201.123-0.786%201.713-0.432%200.594%200.354%200.787%201.122%200.432%201.715l-6.5%2010.875c-0.195%200.326-0.528%200.546-0.905%200.597-0.057%200.008-0.113%200.012-0.169%200.012z%22%20fill%3D%22%234E9F92%22%2F%3E%3C%2Fsvg%3E');
	}
}

div.produktempfehlungen{
  h3{
	font-size: 1rem;
	margin-bottom: 5px;
	padding-bottom: 0!important;
  }

  img{
	//max-width: 250px!important;
	margin-bottom: 10px;
  }

}

.product-list-headline{
	margin-top: 20px!important;
  	padding-top: 20px!important;
  	margin-bottom: 0!important;
}

div.product-details{



  .th{
	margin-top: 0!important;
	font-weight: bold;
	padding-left: 15px!important;
  }

  .rte-text{
	p:last-child{
	  margin-bottom: 0!important;
	}
  }

  #features, #events, #seminars{
	table{
      /*
	  td{
		padding-top: 30px!important;
		padding-bottom: 30px!important;
	  }

	  td:first-child{
		padding-left: 20px;
	  }

	  tr:last-child{
		border-bottom: none!important;
	  }
      */
	  .aft-icon{
		p{
		  padding-left: 0!important;
		}
	  }
	}
  }

  .produkt-feature{
	margin:30px 0;

	&:first-child{
	  margin-bottom: 0px;
	}
	&:last-child{
	  border-bottom:none;
	  margin-bottom: 0px;
	}
  }

  li a.icon-down {
	background-position: -10px center;
	padding-left: 25px;
  }

}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.rte-text{
  ol,ul{
	li{
	  margin-bottom: 5px;
	}
  }

  ol{

	li{
	  list-style-type: decimal;
	}
  }

  ul{
	list-style-type: disc;
  }

}

.kwscilvet-groups{
  .row.equalize{
	background: none;
  }
}
