#shop-listing{
  h1{
    margin-bottom: 10px!important;
  }

  .filter-form{
    fieldset{
      margin-right: 0;
      padding-right: 0;

      min-width: 100%;
      padding-left: 0;

      @media screen and (min-width:@screen-sm-min) {
        min-width: 300px;
        padding-left: 10px;
      }
    }
  }


  .shopProductUL{
    li{
      a{
        .title{
          .fontize(26px);
          .unitLight();
          .greyDark()
        }

        .subtitle{
          .fontize(17px);
          .unitLight();
          .grey();
        }

        .price{
          margin-bottom: 20px;
          .fontize(22px);
          .unitLight();
          .greyDark();

          &.reduced{
            color:@red;
          }

          .price-orig-wrapper{
            display: inline-block;
            .fontize(14px);
            .greyDark();

            .price-orig{
              display: inline-block;
              text-decoration: line-through;
            }
          }
        }

        .more{
          .fontize(17px);
        }

        .img{
          position: relative;

          .flag{
            height: 40px;
            vertical-align: middle;
            padding: 0 20px;
            color: white;
            background: @red;
            position: absolute;
            left: 0;
            bottom: 20px;
            .fontize(20px,40px);
          }
        }

      }
    }
  }

}

#product-detail{
  h1{
    margin-bottom: 10px!important;
  }

  h2{
    .fontize(26px);
    .unitLight();
    .grey();
    margin-top: 0!important;
  }

  .img{
    position: relative;

    .flag{
      height: 40px;
      vertical-align: middle;
      padding: 0 20px;
      color: white;
      background: @red;
      position: absolute;
      left: 0;
      bottom: 20px;
      .fontize(20px,40px);
    }
  }

  .desc{

    background:#ececee;
    padding: 15px 0;

    .desc-title, .desc-line, .desc-price{
      display: block;
      padding-bottom: 10px;
    }

    .desc-title{
      .fontize(17px);
      .unitLight();
      .grey();
    }

    .desc-line{
      .fontize(17px);
      .unitLight();
      .greyDark();

      &.small{
        .fontize(14px);
      }

    }


    .desc-price{
      .fontize(24px);
      .unitLight();
      .greyDark();
      padding-bottom: 0;

      &.reduced{
        color:@red;
      }

      .price-orig-wrapper{
        display: inline-block;
        .fontize(14px);
        .greyDark();

        .price-orig{
          display: inline-block;
          text-decoration: line-through;
        }
      }


    }

  }


  .additional-desc{

    padding-bottom: 30px;
    border-bottom: 1px solid @greyLight;

    .additional-desc-label, .additional-desc-field{
      display: block;
      .fontize(17px);
    }

    .additional-desc-label{
      .grey();
      margin-bottom: 5px;
    }
  }

  .links{

    padding-bottom: 30px;
    border-bottom: 1px solid @greyLight;

    .links-label, .links-field{
      display: block;
      .fontize(17px);
    }

    .links-label{
      .grey();
      margin-bottom: 5px;
    }
  }

  .list{
    padding-bottom: 30px;
    border-bottom: 1px solid @greyLight;

    .list-label{
      display: block;
      .fontize(17px);
      .grey();
      margin-bottom: 5px;
    }


    ul{
      padding-left: 16px;
      list-style-position: outside;
      li{
        list-style: disc;
        margin-bottom: 5px;
      }
    }

  }


  &.product-link{

    h3{
      padding-bottom: 20px;
    }

    .img{
      max-width: 100%;
    }

    & > .row{
      padding-bottom: 30px;
      border-bottom: 1px solid @greyLight;
      margin-bottom: 30px;
    }
  }

}