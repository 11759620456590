#main form  {
	
	.placeholderError() {
		color: @red;
	}

	.parsley-errors-list {display:none !important; } //dont show messages (simpler than '<form data-parsley-errors-messages-disabled')
	
	input.parsley-error::-webkit-input-placeholder { .red !important;	}
	input.parsley-error:-moz-placeholder {	.red !important; } // Firefox 18-
	input.parsley-error::-moz-placeholder { .red !important; } // Firefox 19+
	input.parsley-error:-ms-input-placeholder { .red !important; }



	input[type="text"].parsley-error,
	input[type="email"].parsley-error,
	select.parsley-error,
	textarea.parsley-error {
	  color: @red;
	  //background-color: #F2DEDE;
	  border: 1px solid  @red;
	  outline: 1px solid  @red; //so is 2px think in the end.
		
		&::-webkit-input-placeholder	{ .placeholderError(); }
		&:-moz-placeholder { .placeholderError(); }
		&:-ms-placeholder { .placeholderError(); }
		&::-ms-input-placeholder	{ .placeholderError(); }
		&:-ms-input-placeholder	{ .placeholderError(); }
	}
	
	.btn-group.bootstrap-select.parsley-error {
	  color: @red;
	  //background-color: #F2DEDE;
	  border: 1px solid  @red;
	  outline: 1px solid  @red; //so is 2px think in the end.
	}
	
	
	.parsley-errors-list {
		//border:Red solid 3px;
	}
	
	.parsley-errors-list {
		color:@white;
		list-style:none;
		//padding:3px;
	}
//	.parsley-errors-list {
//		display:none;
//	}
	#parsley-id-multiple-countrySelect, 
	#parsley-id-multiple-audienceSelect, 
	#parsley-id-multiple-beneficiarySelect, 
	#parsley-id-multiple-objectiveSelect, 
	#parsley-id-multiple-activitySelect, 
	#parsley-id-multiple-organisationSelect
	{
		&.parsley-errors-list {
			display:block;
			margin:0;
			padding:0;
			
			.parsley-required {
				background:@red;
				display:inline-block;
				padding:3px;
				font-size:12px !important;
			}
			
			.parsley-required:before {
				content: " ";
				    border-left: 4px solid transparent;
					border-right: 4px solid transparent;
					border-bottom: 4px solid @red;
					display: inline-block;
					display: inline-block;
					height: 0;
					margin-top: -7px;
					margin-left: -3px;
					vertical-align: middle;
					width: 0;
					position: absolute;
			}
		}
	}
	

	.parsley-errors-list.filled {
	  opacity: 1;
	}

	/*
	.selector .parsley-errors-list {
		position:absolute;
		margin-top:-35px;
		height:35px;
		border:2px @red solid;
	}
	*/
	
	//selectify:
	.bootstrap-select + .parsley-errors-list.filled {display:none;} //dont show UL
	select.parsley-error + .bootstrap-select button.selectpicker { //border on pseudo select
	  border: none;
	  outline: 2px solid  @red; //so is 2px think in the end.
		
		span {
			.red;
		}
	}

}