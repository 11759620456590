#main {
	
	.teasers {
		.col-xs-12 {
			margin-bottom:15px;
			@media screen and (min-width: @screen-md) {
				margin-bottom:0;
			}	
		}
	}

	.meta {
		.greyMid;
	}
	h2.icon-arrow, h3.icon-arrow {
		display:inline-block;
		background-position:center right;
		padding-bottom:5px;
		padding-right:40px;
		.margin-bottom;
	}
	h3.icon-arrow {
		padding-right:35px;
	}


	article.teaser {
		h1 {
			.fontizeBody;
			font-weight:bold;
			.margin-top-only;
			padding:0;
		}
	}

	.teaser {
		
		p, img {
			.margin-bottom;
		}
		
		.address {
			margin-bottom:30px;
		}
	}

	.aktuelles-item {
		//.border-bottom;
		.border-top;
		.padding-tb;
	}
	.aktuelles-item:first-of-type {
		border-top:0;
	}

	.quotes {
		&:before {
			content: "« ";
		}
		&:after {
			content: " »";
		}
	}
	
	.owl-carousel-seminar {
		margin-top: 30px;
		
		.teaser {
			@media screen and (min-width: @screen-sm) {
				padding-right: 30px;
				max-width: 480px;
			}
			.h3 {
				padding-bottom: 15px;
			}
		}
	}

}
