#main {
	max-width:1280px;
	margin:0 auto;
	
	hr {
		background: none;
		border-top: 1px solid @greyLight;
		display: block;
		height: 1px;
	}


	// containers/sections etc:
	.container-fluid {
		.margin-top(30px);
	}
	.container-fluid.margin-top0 {
		margin-top:0;
	}
	

	// intro bit
	.header {
		.container-fluid .container-fluid {
			.margin-top0;
		}
		margin-bottom:50px;
	}

	.produkt-feature {
		.border-bottom;
		padding-bottom:30px;
	}
	.section {
		.border-bottom;
		.padding-bottom;
	}
	
	h1 {
		.padding-tb(@marginX2);
		.margin-top(@marginX2);
		.padding-bottom(20px);
	}
	h2, h3, h4, h5, h6 {
		.padding-bottom(@marginX2);
	}
	h3.h4 {padding-bottom:0;}

	
	// BE CONSISTANT!
	h2:not(.icon-arrow){
		//.border-bottom; ?!?
		.padding-bottom(0);
		.margin-top(30px);
		.margin-bottom(30px);
	}
	.hidden-xs h2,
	.carousel h2,
	.intro-box h2,
	.page h2 { // e.g. produkt header, carousel
		border:none;
		margin:0;
		// padding-top:10px;
		margin-top:10px;
	}
	.hidden-xs h2 {
		margin-top:30px;
	}

	h1:first-child {
		margin-top:0;
		padding-top:0;
		margin-bottom:30px; //this
		padding-bottom:0; //this
	}
	.col-sm-8 > h1 {
		margin-bottom:0;
	}
	
	
	p {
		margin-top:0;
		.margin-bottom(@marginX2);
		//.padding-bottom(@marginX2);
	}
	
	.page {
		
		h2, h3, h4, h5, h6 {
			.border-top;
			.padding-tb(@marginX2);
			.margin-top(@marginX2);
		}
		h2 {.h3;}
		h1:first-child {
			margin-top:0;
			padding-top:0;
		}
		h2:first-of-type {
			border:none;
		} // ?!? CAUTION: could fail if ever border is needed on 1st-child
		.page h2 {
			margin-top:0;
		}
		
		.header, h2 {margin:0;}
	}
	
	

}
