/* CSS not LESS */
@media print {
	
	body > header:before {
		content: "scilvet"; //if no BG
		content: url("../img/scil.png");
		//content: "scilvet";
		display:block;
		margin-bottom:20px;
	}	
	
	* {
		color: #000;
	}
	
	#main a {
		text-decoration:none !important;
		border-color:#fff;
		border:none !important;
		border-bottom:none !important;
	}
	#main a:after { //wont get IMG
		font-size:10px !important;
		font-style: italic;
		color:#666 !important;
		content: " [link]" !important;
		text-decoration:none !important;
		border:none !important;
	}
	
	body > header .x-container,
	body >footer a,
	.goUp, 
	aside, 
	form, button, input,
	footer hr, footer .hr,
	.carousel {
		display:none;
	}
	
	body > footer {
		border-top:dashed 1px #ccc;
		padding-top:15px;
		margin-top:15px;
	}
	
	/*
	
	#debug {display:none !important;}

	nav, form, .up2top, .carousel, iframe {
		display:none !important;
	}
	aside {
		display:none;
	}

	.elinet a {
		display: block;
		width: 100%;
		height: inherit;
		text-indent: 0;
		background: url('../img/elinet_logo.png') no-repeat !important;
	}
	
	button, .buttpn {border: 1px sold #ccc;}
	*/
		

}