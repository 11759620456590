// iCheck plugin Minimal skin, grey
//- SEE below - using MCL styles


/*
.icheckbox_minimal-grey,
.iradio_minimal-grey {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: url('../img/checkbox.png') no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_minimal-grey {
    background-position: 0 0;
}
    .icheckbox_minimal-grey.hover {
        background-position: -20px 0;
    }
    .icheckbox_minimal-grey.checked {
        background-position: -40px 0;
    }
    .icheckbox_minimal-grey.disabled {
        background-position: -60px 0;
        cursor: default;
    }
    .icheckbox_minimal-grey.checked.disabled {
        background-position: -80px 0;
    }

.iradio_minimal-grey {
    background-position: -100px 0;
}
    .iradio_minimal-grey.hover {
        background-position: -120px 0;
    }
    .iradio_minimal-grey.checked {
        background-position: -140px 0;
    }
    .iradio_minimal-grey.disabled {
        background-position: -160px 0;
        cursor: default;
    }
    .iradio_minimal-grey.checked.disabled {
        background-position: -180px 0;
    }

// HiDPI support
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    .icheckbox_minimal-grey,
    .iradio_minimal-grey {
        background-image: url(../img/checkbox@2x.png);
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }
}

*/

/* MCL */
label {
	.icheckbox, .iradio {
		margin-right:5px;
	}
}

.icheckbox,
.iradio {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 22px;
 height: 22px;
    line-height: 22px;
	
    .border;
    cursor: pointer;
	
	&:hover {
		.bg(@greyLighter);
	}
	&.checked {
		background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20id%3D%22tick-Ebene_1%22%20xml%3Aspace%3D%22preserve%22%20height%3D%2240%22%20viewBox%3D%220%200%2040%2040%22%20width%3D%2240%22%20version%3D%221.1%22%20y%3D%220px%22%20x%3D%220px%22%20enable-background%3D%22new%200%200%2040%2040%22%3E%09%3Cpath%20d%3D%22m19.25%2027.375c-0.32%200-0.629-0.123-0.864-0.347l-4.319-4.129c-0.499-0.477-0.517-1.268-0.04-1.767s1.268-0.518%201.768-0.04l3.187%203.046%205.697-9.53c0.354-0.593%201.123-0.786%201.713-0.432%200.594%200.354%200.787%201.122%200.432%201.715l-6.5%2010.875c-0.195%200.326-0.528%200.546-0.905%200.597-0.057%200.008-0.113%200.012-0.169%200.012z%22%20fill%3D%22%234E9F92%22%2F%3E%3C%2Fsvg%3E');
		background-repeat:no-repeat;
		background-position:center center;
	}
}

// no SVG support
.no-svg .icheckbox,
.no-svg .iradio {
	
	&.checked {
		background-image:url('img/icons.png');background-position:0 -740px;
		background-repeat:no-repeat;
	}
}

