// main: base.less

.navbar, .navbar .navbar-default  {
	min-height:127px;
	background:@white;
	// background:none;
	border:none;
	max-width:1280px;
	.border-bottom;
	border-radius:0;
	margin:0 auto;

	// mob menu button:
	/*
	.navbar-toggle {
		.bg(@green);
		.icon-bar {
			.bg(@white);
		}
	}
	*/

	.navbar-collapse {
		// .bg(@white);
	}

	.container-fluid {
	//	padding:0;
	}
	
	button.navbar-toggle {
		margin-top: 72px;
	}
	a.dropdown-toggle, a:hover.dropdown-toggle {
		//.greyDark;
	}
	
	//logo
	.navbar-brand {
		/*
		background-image:url('../img/scil.png');
		background-position: center right;
		background-repeat: no-repeat;
		*/

		// NON STRETCH:

		background-image:url('../img/logo-scil-antech.jpg');
		background-size:145px 121px;
		margin-top:-5px;

		@media screen and (max-width: @screen-sm) {
			margin-left:15px;
		}

		background-position: center right;
		background-repeat: no-repeat;

		text-indent:-9999px;
		color:transparent;
		height:130px;
		
		width:150px;
		@media screen and (min-width: @bp-indentify) { // was @bp-max-screen
			width:160px;
		}
	}
    // CANADA:
	.navbar-brand.navbar-canada {
		background-image:url('../img/scilvet-heska-logo-weissraum.svg');
		background-size: contain;
		height: 103px;
		@media screen and (max-width: @screen-sm) {
			height: 112px;
		}
		width: 210px;
		background-position: center bottom;
		//	@media screen and (max-width: @screen-sm) {
		//		margin-left: 15px;
		//	}
    }
	
	.navbar-nav > li > a {.greyDark;}

	.nav > li > a {
		.fontizeNav;
		/*
		@media screen and (min-width: @screen-md) {
			padding:20px 0 20px 20px;
		}
		@media screen and (min-width: @bp-nav) {
			padding:20px;
		}
		*/
		padding:20px 0 20px 20px;
	}
	@media screen and (min-width: @bp-desk-min) {
		//.nav > li li a {.fontize(24px,40px);}
		.nav > li li a {.fontize(1.2rem,2rem);}
	}
	

	ul.navbar-nav {

		@media screen and (min-width: @bp-desk-min) {
			margin: 60px 0 0 20px;
		}

		@media screen and (min-width: @bp-indentify) {
			margin: 60px 0 0 90px;
		}
	}
	
	.nav.navbar-nav a, 
	.nav.navbar-nav a:hover, 
	.nav.navbar-nav > a:focus {
		background:none !important;
	}
	
	// .caret {.greyDark;}
	.caret:after {
		content: "";
		display:block;
		//position:absolute;
		//left:0;
		//top:0;
		margin: -6px 0 0 -4px;
		width: 0;
		height: 0;
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-top: 4px solid @white;
	}
	
	// arrow on nav dropdown
	li > ul.dropdown-menu {
		padding:0;
		.border(@greyLight);
		border-radius:0;
		margin-left:20px;
		margin-top:10px;
		
		li a {line-height:1.7rem;}
	
		@media screen and (min-width: @bp-desk-min) {
			&:after {
				content: " ";
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid @white;
				position:absolute;
				top:-9px;
				left:15px;
				z-index:1036; // +1 of popup
			}
			&:before {
				content: " ";
				width: 0;
				height: 0;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-bottom: 10px solid @greyLight;
				position:absolute;
				top:-10px;
				left:15px;
			}
		}
		
		a { // ?!?  correct ?
			// font-weight:bold;
			// .fontize24;
		}
		a:hover {
			background:none;
			.green;
		}
	}
	//LANG
	ul.navbar-right {
		// float:right;
		margin-right:-15px;
		@media screen and (min-width: @bp-indentify) { // @bp-max-screen doesnt allow dropdown space
			margin-right:-45px;
		}
		
		
		li {
			> ul.dropdown-menu {
				min-width: 0;
				margin-top:-10px;
				padding:5px 0;
				
				&:after, &:before {
					left:auto !important;
					right:15px !important;
				}
				li a {
					.fontizeNav;
				}
			}
			a.icon-net, a:hover.icon-net, a:focus.icon-net { // see icons.less
				background-color:#fff !important;
				background-position: left center;
				background-size: 30px auto;
				font-size:15px;
				text-indent:15px;
			}
			> span {
				display:block;
				padding-left:20px;
				padding-right:20px;
				min-width:175px;

				a {
					display:inline-block;
					background-color: #ececee;
					border: 1px solid #d7d7d7;
					height: 22px;
					text-align: center;
					width: 24px;
					margin-left:5px;

					&:hover {text-decoration:none;}
				}
			}


		}
	} // end ul.navbar-right
	.navbar-form.navbar-right:last-child {
		margin-right:-15px;
		@media screen and (min-width: @bp-indentify) { // @bp-max-screen doesnt allow dropdown space
			margin-right:-30px;
		}
	}
	
	@media screen and (max-width: @bp-mob-max) {
		.navbar-right li.open ul.dropdown-menu {
			display:block !important;
			position:relative !important;
			box-shadow:none !important;
			border:none !important;
		}
	} // end MQ
	
	
	//form:
	.navbar-form {
		.bg(@white);
		clear:right;
		border:none;
		
		padding: 0;
		/*
		@media screen and (min-width: @bp-desk-min) {
			margin-right: -25px;
			padding: 0;
			clear:right;
		}
		width:150px;
		*/
		margin-left:0;
		@media screen and (min-width: @screen-sm) {
			margin-left:-15px;
		}

		@media screen and (min-width: @bp-max-screen) {
			margin-right: -30px;
		}

		.input-group {
			margin-right:15px;
			@media screen and (min-width: @screen-sm) {
				margin-right:0;
			}
		}
	}

	

} //close navbar

//maks sure is always on top;
ul.nav, ul.nav {
	z-index:2001; // so is above slider
	position:relative;
}

#country-switch{
	z-index: 2003;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus,
.navbar-default .navbar-nav > li.dropdown.open > a {
	color:@green;
}


.no-js, .no-svg {
	.navbar-brand {
		background-image:url('../img/logo-scil-antech.jpg');
	}
}
