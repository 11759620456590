

.noscriptMessage {

	/*
	position: fixed;
	z-index: 1000;
	left: 0;
	right: 0;
	top: 0;
	*/
	padding: 1em 0.5em;
	text-align: center;
	
	background: #ffffe1;
	// outline: 1px solid #900;
	
	a { color: #900; }
	

}

// only show DE/EN lang
html[lang="de"] .noscriptEN .noscriptMessage { display:none; }
html[lang="en"] .noscriptDE .noscriptMessage { display:none; }


.no-js {

	// styles for JS Disabled.
	// styles for before html.js enabled.
	
	input[type="checkbox"] {
		width:auto !important;
		height:auto !important;
		border:1px #999 solid;
	}
	
	body > footer .expando button {
		display:none;
	}
	
	.sitemap {
		display:block !important;
		a {
			font-weight:bold;
		}
	}
	fieldset {height:auto !important;}
	
	aside.sidebar {display:none;}
}

